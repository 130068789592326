import React, { useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import moment from "moment";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../../global";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Grid, TextField, Autocomplete, Box } from "@mui/material";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { useTheme } from "@mui/material/styles";
import LastPageIcon from "@mui/icons-material/LastPage";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import ExcelDownload from "../../utils/components/excelDownload";
import ListWrap from "../commonComponent/listWrap";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");

const columns = [
  { id: "id", label: "Sr. No.", minWidth: 40 },
  { id: "clientName", label: "Client", minWidth: 30 },
  {
    id: "invoiceNumber",
    label: "Invoice Number",
    minWidth: 40,
  },
  {
    id: "invoiceDate",
    label: "Invoice Date",
    align: "left",
    minWidth: 40,
  },

  {
    id: "gstNumber",
    label: "Gst Number",
    minWidth: 40,
    align: "left",
  },
  {
    id: "sacCode",
    label: "Sac Code",
    minWidth: 40,
    align: "left",
  },

  {
    id: "totalAmount",
    label: "Total Amount",
    minWidth: 40,
    align: "left",
  },
  {
    id: "cgst",
    label: "cgst",
    minWidth: 40,
    align: "left",
  },
  {
    id: "sgst",
    label: "sgst",
    minWidth: 40,
    align: "left",
  },
  {
    id: "totalGst",
    label: "Total Gst",
    minWidth: 40,
    align: "left",
  },
];

function createData(
  id,
  clientName,
  invoiceNumber,
  invoiceDate,
  gstNumber,
  sacCode,
  totalAmount,
  cgst,
  sgst,
  totalGst
) {
  return {
    id,
    clientName,
    invoiceNumber,
    invoiceDate,
    gstNumber,
    sacCode,
    totalAmount,
    cgst,
    sgst,
    totalGst,
  };
}
const notNeededColumn = ["id", "clientId", "createdBy", "status"];

export default function GstReport() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [searchBy, setSearchBy] = React.useState("Client");
  const [selectDateBy, setSelectDateBy] = React.useState("");
  const [searchKey, setSearchKey] = React.useState("");
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const [totalRows, setTotalRows] = React.useState(0);
  const [paymentList, setPaymentList] = React.useState([]);
  const [paymentData, setPaymentData] = React.useState([]);
  const [client, setClient] = React.useState(0);
  const [fromDate, setFromDate] = React.useState(0);
  const [toDate, setToDate] = React.useState(0);
  const [pushArrays, setPushArray] = React.useState([]);
  const [dateData, setDateData] = React.useState([]);
  const [companyName, setCompanyName] = React.useState(null);
  var [cgst, setCgst] = React.useState(null);
  var [sgst, setSgst] = React.useState(null);
  var [totGst, setTotGst] = React.useState(null);
  var [totAmt, setTotAmt] = React.useState(null);
  var handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const [searched, setSearched] = React.useState(false);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getData = (count) => {
    let whereConditions = "";
    let pagination_settings = "";

    if (searchBy !== "" && searchKey != "") {
      let colName = {
        Client: "companyName",
        "Invoice Number": "invoiceNumber",
      }[searchBy];
      whereConditions = `where ${colName} like '%${searchKey}%'`;
    }

    if (to != null && from != null) {
      if (whereConditions === "") {
        whereConditions = " where ";
      } else {
        whereConditions += " and ";
      }

      whereConditions += ` paymentMaster.invoiceDate between '${moment(
        from
      ).format("YYYY-MM-DD")}' and '${moment(to).format("YYYY-MM-DD")}' `;
    }

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }

    if (whereConditions === "") {
      whereConditions = " where ";
    } else {
      whereConditions += " and ";
    }

    whereConditions += " paymentMaster.status = 1 ";

    let data = {
      query: `SELECT paymentMaster.id, paymentMaster.totalAmount, paymentMaster.address, paymentMaster.contactNumber, paymentMaster.conveyanceCharges, paymentMaster.discount, paymentMaster.gstType, paymentMaster.invoiceDate, paymentMaster.invoiceNumber, paymentMaster.poNumber, paymentMaster.subject, clients.companyName, paymentMaster.paymentDate, paymentMaster.paymentMode, clients.gstNumber, paymentMaster.cgst, paymentMaster.sgst FROM paymentMaster JOIN clients ON paymentMaster.clientId = clients.id ${whereConditions} order by paymentMaster.id DESC ${pagination_settings}`,
    };

    if (count)
      data.query = `select count(paymentMaster.id) as no_of_rows FROM paymentMaster AS paymentMaster JOIN clients AS client ON paymentMaster.clientId = client.id ${whereConditions} order by paymentMaster.id DESC ${pagination_settings}`;
    return data;
  };

  const getPaymentList = (event) => {
    const data = getData();

    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        setPaymentList(res.data);
        setDateData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const search = () => {
    setSearched(true);
    getPaymentList();
  };

  const clearSearch = () => {
    setSearchBy("Client");
    setSearchKey("");
    setFrom(null);
    setTo(null);
    setSearched(false); 
  };

  function getTotalRows() {
    const data = getData("count");
    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then(async (res) => {
        setTotalRows(res.data[0]?.no_of_rows);
      })
      .catch((err) => {
        console.log("clients data fetching error: ", err);
      });
  }

  const initiatInvoiceList = () => {
    var rowData = [];
    var totalCgst = 0;
    var totalSgst = 0;
    var totalGst = 0;
    var totAm = 0;
    for (let i = 0; i < dateData.length; i++) {
      totAm += dateData[i].totalAmount;
      totalCgst += dateData[i].cgst;
      totalSgst += dateData[i].sgst;
      totalGst += dateData[i].cgst + dateData[i].sgst;
      rowData.push(
        createData(
          dateData[i].id,
          dateData[i].companyName,
          dateData[i].invoiceNumber,
          dateData[i].invoiceDate
            ? moment(paymentList[i].invoiceDate).format("DD-MM-YYYY")
            : "",

          dateData[i].gstNumber,
          "sacCode",
          dateData[i].totalAmount,
          dateData[i].cgst,
          dateData[i].sgst,
          dateData[i].cgst + dateData[i].sgst
        )
      );
      setCgst(totalCgst);
      setSgst(totalSgst);
      setTotGst(totalGst);
      setTotAmt(totAm);
    }
    setPaymentData(rowData);
  };

  const getClient = (event) => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + "clients?_where=(status,eq,1)")
      .then((res) => {
        setClient(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const TablePaginationActions = (props) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  };

  const intializeClient = () => {
    var pushArray = [];
    for (let i = 0; i < client.length; i++) {
      pushArray.push({
        label: client[i].companyName,
      });
    }
    setPushArray(pushArray);
  };

  useEffect(() => {
    intializeClient();
  }, [client]);

  useEffect(() => {
    initiatInvoiceList();
  }, [dateData]);

  useEffect(() => {
    getClient();
    getTotalRows();
  }, []);

  useEffect(() => {
    getPaymentList();
  }, [rowsPerPage, page]);

  useEffect(() => {
    if(searched==false){
      getPaymentList();
    }
    if (searched) {
      setPage(0);
    }
  }, [searched]);

  return (
    <ListWrap>

        <div
          className="mb-2"
          style={{
            display: "flex",
            float: "right",
            justifyContent: "flex-end",
          }}
        >
          <div style={{ marginRight: "10px", width:"120px" }}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              classNames="mr-2"
            >
              <DatePicker
                className="Listinputcss"
                slotProps={{ textField: { size: "small", fullWidth: true } }}
                label="From"
                value={from ? new Date(from) : ""}
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                onChange={(newValue) => {
                  setFrom(newValue);
                  setSearched(false);
                }}
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </LocalizationProvider>
          </div>

          <div style={{ marginRight: "10px", width:"120px" }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                className="Listinputcss"
                slotProps={{ textField: { size: "small", fullWidth: true } }}
                label="To"
                value={to ? new Date(to) : ""}
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                onChange={(newValue) => {
                  setTo(newValue);
                  setSearched(false);
                }}
                renderInput={(params) => <TextField {...params} size="small" />}
                style={{ marginRight: "20px" }}
              />
            </LocalizationProvider>
          </div>

          <Autocomplete
            sx={{
              m: 0,
              minWidth: 180,
              marginLeft: "10px",
            }}
            className="Listinputcss"
            size="small"
            value={searchBy}
            id="combo-box-demo"
            options={[
              { key: "client.companyName", label: "Client" },
              { key: "payment.invoiceNumber", label: "Invoice Number" },
            ]}
            renderInput={(params) => (
              <TextField {...params} label="Search By" />
            )}
            onInputChange={(event, newInputValue) => {
              setSearchBy(newInputValue);
              setSearched(false);
            }}
          />
          <TextField
            id="outlined-basic"
            inputProps={{
              autoComplete: 'off',
            }}
            label={"enter " + searchBy}
            size="small"
            className="Listinputcss"
            value={searchKey}
            variant="outlined"
            onChange={(e) => {
              setSearchKey(e.target.value);
              setSearched(false);
            }}
            sx={{ marginLeft: "10px" }}
          />
          <Button
            variant="contained"
            size="small"
            sx={{ m: 0 }}
            style={{ marginLeft: "10px" }}
            color={searched ? "error" : "primary"}
            onClick={() => {
              searched ? clearSearch() : search();
            }}
          >
            {searched ? <RestartAltIcon /> : <SearchIcon />}
          </Button>
          <ExcelDownload
            finalData={paymentData}
            notNeededColumn={notNeededColumn}
          />
        </div>

        <TableContainer>
          <Table
            stickyHeader
            aria-label="sticky table"
            size="small"
            id="datatable-keytable"
            sx={{ borderCollapse: "collapse" }}
          >
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <b>{column.label}</b>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentData?.length > 0 ? (
                paymentData.map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      let value = row[column.id];
                      if (column.id == "id") {
                        value = index + 1;
                      }
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length + 1} align="center">
                 <h6><b>Data Not Found</b></h6>
                </TableCell>
              </TableRow>
            )} 
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100, { label: "All", value: -1 }]}
            component="div"
            count={totalRows}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
          <ToastContainer />
          <br />
          {paymentData.length ? (
            <Grid
              container
              spacing={2}
              style={{ marginBottom: "10px" }}
              justifyContent="flex-end"
            >
              <Grid item xs={2} justify="flex-end">
                <TextField
                  id="outlined-basic"
                  label="Total Amount"
                  disabled
                  value={totAmt != null && totAmt != 0 ? totAmt : ""}
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
            </Grid>
          ) : (
            ""
          )}
          {paymentData.length ? (
            <Grid
              container
              spacing={2}
              style={{ marginBottom: "10px" }}
              justifyContent="flex-end"
            >
              <Grid item xs={2} justify="flex-end">
                <TextField
                  id="outlined-basic"
                  label="Total CGST"
                  disabled
                  minvalue="100"
                  value={cgst != null && cgst != 0 ? cgst : ""}
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
            </Grid>
          ) : (
            ""
          )}
          {paymentData.length ? (
            <Grid
              container
              spacing={2}
              style={{ marginBottom: "10px" }}
              justifyContent="flex-end"
            >
              <Grid item xs={2} justify="flex-end">
                <TextField
                  id="outlined-basic"
                  label="Total SGST"
                  disabled
                  minvalue="100"
                  value={sgst != null && sgst != 0 ? sgst : ""}
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
            </Grid>
          ) : (
            ""
          )}
          {paymentData.length ? (
            <Grid
              container
              spacing={2}
              style={{ marginBottom: "10px" }}
              justifyContent="flex-end"
            >
              <Grid item xs={2} justify="flex-end">
                <TextField
                  id="outlined-basic"
                  label="Total GST"
                  disabled
                  minvalue="100"
                  value={totGst != null && totGst != 0 ? totGst : ""}
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
            </Grid>
          ) : (
            ""
          )}
        </TableContainer>
    </ListWrap>
  );
}
