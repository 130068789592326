import React, { useEffect } from "react";
import { BASE_URL } from "../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import Toolbar from "@mui/material/Toolbar";
import { Link, useLocation } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import PrintIcon from "@mui/icons-material/Print";
import HelpIcon from "@mui/icons-material/Help";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import moment from "moment";
import { TextField, Autocomplete, Modal, Typography, Grid, useMediaQuery, Checkbox } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import LastPageIcon from "@mui/icons-material/LastPage";
import IconButton from "@mui/material/IconButton";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import { showComponent } from "../helper/helpers";
import TextEditor from "./textEditor";
import ExcelDownload from "../../utils/components/excelDownload";
import { quotationListGuide } from "./guide";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import ErrorModal from "../../utils/components/errorModal";
import EditWrap from "../commonComponent/editWrap";
import RenderEmailModal from "../../utils/components/renderEmailModal";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");



const columns = [
  { id: "id", label: "Sr. No.", minWidth: 40 },
  { id: "companyName", label: "Client", minWidth: 40 },
  { id: "address", label: "Address", minWidth: 40 },
  { id: "clientId", label: "Contact Details", minWidth: 60 },
  { id: "quotationNumber", label: "Quotation Number", minWidth: 50 },
  {
    id: "date",
    label: "Quotation Date",
    minWidth: 40,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  {
    id: "totalAmount",
    label: "Total Amount",
    align: "left",
    minWidth: 40,
  },
  {
    id: "createdBy",
    label: "Created By",
    align: "left",
    minWidth: 40,
  },
];

function createData(
  id,
  companyName,
  address,
  referenceNumber,
  date,
  totalAmount,
  quotationNumber,
  clientId,
  subject,
  equipmentCollection,
  equipmentDelivery,
  conveyanceCharges,
  courierCharges,
  enquiryDate,
  enquiryId,
  createdBy
) {
  return {
    id,
    companyName,
    address,
    referenceNumber,
    date,
    totalAmount,
    quotationNumber,
    clientId,
    subject,
    equipmentCollection,
    equipmentDelivery,
    conveyanceCharges,
    courierCharges,
    enquiryDate,
    enquiryId,
    createdBy,
  };
}
const notNeededColumn = [
  "id",
  "status",
  "referenceNumber",
  "clientId",
  "subject",
  "equipmentCollection",
  "conveyanceCharges",
  "enquiryDate",
  "enquiryId",
  "createdBy",
  "courierCharges",
  "equipmentDelivery",
];

export default function QuotationList() {
  const quotationListGuides = quotationListGuide();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const urlPath = useLocation();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [quotationList, setQuotationList] = React.useState([]);
  const [quotationData, setQuatationData] = React.useState([]);
  const [searchBy, setSearchBy] = React.useState(sessionStorage.getItem("searchBy") || "Quotation Number");
  const [searchKey, setSearchKey] = React.useState(sessionStorage.getItem("searchKey") || "");
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const [totalRows, setTotalRows] = React.useState(0);
  const userName = localStorage.getItem("userName");
  const [users, setUsers] = React.useState([]);
  const [clients, setClients] = React.useState([]);
  const [errormodalIsOpen, setErrorModalIsOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [searched, setSearched] = React.useState(false);
  const [filterModalOpen, setFilterModalOpen] = React.useState(false);
  const [editAccess, setEditAccess] = React.useState(localStorage.getItem("editAccess")?.split(",").map(Number));
  const [open, setOpen] = React.useState(false);
  const [selectedColumn, SetSelectedColumn] = React.useState([]);
  const [selected, setSelected] = React.useState([]);
  const handleSelectRow = (id) => {
    setSelected((prev) => {
      if (prev.includes(id)) {
        return prev.filter((item) => item !== id);
      } else {
        return [...prev, id];
      }
    });
  };
  const isSelected = (id) => selected.includes(id);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFilterOpen = () => setFilterModalOpen(true);
  const handleFilterClose = () => setFilterModalOpen(false);

  const openErrorModal = (errorMessage, stackTrace, fileName) => {
    setErrorMessage({ errorMessage, stackTrace, fileName });
    setErrorModalIsOpen(true);
  };
  const closeErrorModal = () => {
    setErrorModalIsOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const submitSiteDelete = (id) => {
    const idString = (Array.isArray(id) ? id : [id]).join(',');

    let quotationsQuery = {
      query: `DELETE FROM quotation WHERE id IN (${idString});`
    };
    let productdetailsQuery = {
      query: `DELETE FROM productDetails WHERE type = 1 AND referenceId IN (${idString});`
    };

    axiosWithToken
      .post(BASE_URL + `dynamic`, quotationsQuery)
      .then(() => {
        return axiosWithToken.post(BASE_URL + `dynamic`, productdetailsQuery);
      })      
      .then((res) => {
        toast("quotation was deleted!");
        setTimeout(refresh, 500);
      })
      .catch((error) => console.log("err",error));
  };

  var refresh = () => {
    window.location.reload(false);
    // <Link to="/master/master/standard">Home</Link>
  };

  const getData = (count) => {
    let whereConditions = "";
    let pagination_settings = "";

    if (searchBy !== "" && searchKey != "") {
      let colName = {
        Client: "companyName",
        "Reference Number": "referenceNumber",
        "Quotation Number": "qt.quotationNumber",
      }[searchBy];
      whereConditions = `where ${colName} like '%${searchKey}%'`;
    }

    if (to != null && from != null) {
      if (whereConditions === "") {
        whereConditions = " where ";
      } else {
        whereConditions += " and ";
      }

      whereConditions += ` qt.date between '${moment(from).format(
        "YYYY-MM-DD"
      )}' and '${moment(to).format("YYYY-MM-DD")}' `;
    }

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }

    if (whereConditions === "") {
      whereConditions = " where ";
    } else {
      whereConditions += " and ";
    }

    whereConditions += " qt.status = 1 ";

    let data = {
      query: `SELECT qt.createdBy, qt.quotationNumber, qt.date, qt.totalAmount, qt.referenceNumber, qt.enquiryId, qt.id, qt.enquiryDate, qt.conveyanceCharges, qt.courierCharges, qt.equipmentCollection, qt.equipmentDelivery, qt.subject, client.companyName, qt.clientId, a.address AS address FROM quotation AS qt LEFT JOIN clients AS client ON qt.clientId = client.id LEFT JOIN addresses AS a ON a.id = SUBSTRING_INDEX(SUBSTRING_INDEX(qt.address, ',', 1), ',', -1) ${whereConditions}  ORDER BY qt.id DESC ${pagination_settings}`,
    };

    if (count)
      data.query = `select count(qt.id) as no_of_rows FROM quotation AS qt LEFT JOIN clients AS client ON qt.clientId = client.id LEFT JOIN addresses AS a ON a.id = SUBSTRING_INDEX(SUBSTRING_INDEX(qt.address, ',', 1), ',', -1) ${whereConditions} ORDER BY qt.id DESC`;
    return data;
  };

  function getClientList() {
    const data = getData();

    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        const uniqueIds = [];
        const unique = res.data.filter((element) => {
          const isDuplicate = uniqueIds.includes(element.quotationNumber);
          if (!isDuplicate) {
            uniqueIds.push(element.quotationNumber);
            return true;
          }
          return false;
        });
        // setQuotationList(unique);
        setQuotationList([...unique]);
      })
      .catch((err) => {
        console.log("clients data fetching error: ", err);
        if (err.message !== "request_aborted") {
          toast.error(<h6 onClick={() => openErrorModal(err.message, "getClientList" ,"Quotation List")}><u>Error: {err.message}</u></h6>);
        }
      });
  }

  // api calls
  function getTotalRows() {
    const data = getData("count");
    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then(async (res) => {
        setTotalRows(res.data[0]?.no_of_rows);
      })
      .catch((err) => {
        console.log("clients data fetching error: ", err);
        if (err.message !== "request_aborted") {
          toast.error(
            <h6
              onClick={() =>
                openErrorModal(err.message, "getTotalRows", "Quotation List")
              }
            >
              <u>Error: {err.message}</u>
            </h6>
          );
        }
      });
  }

  // api calls
  function getUsers() {
    const data = {
      query: "SELECT id, userName FROM users",
    };
    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then(async (res) => {
        setUsers(res.data);
      })
      .catch((err) => {
        console.log("clients data fetching error: ", err);
        if (err.message !== "request_aborted") {
          toast.error(
            <h6
              onClick={() =>
                openErrorModal(err.message, "getUsers", "Quotation List")
              }
            >
              <u>Error: {err.message}</u>
            </h6>
          );
        }
      });
  }

  // api calls
  function getClients() {
    const data = {
      query: "SELECT id, contact  FROM clients",
    };
    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then(async (res) => {
        setClients(res.data);
      })
      .catch((err) => {
        console.log("clients data fetching error: ", err);
        if (err.message !== "request_aborted") {
          toast.error(
            <h6
              onClick={() =>
                openErrorModal(err.message, "getClients", "Quotation List")
              }
            >
              <u>Error: {err.message}</u>
            </h6>
          );
        }
      });
  }

  const TablePaginationActions = (props) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  };
  const storedSearchBy = sessionStorage.getItem("searched");
  useEffect(() => {
    if(storedSearchBy){
      setSearched(true);
    }
  }, [storedSearchBy]);

  const search = () => {
    sessionStorage.setItem("searchBy", searchBy);
    sessionStorage.setItem("searchKey", searchKey);
    sessionStorage.setItem("searched", "true");
    sessionStorage.setItem("currentPath", urlPath.pathname);
    setSearched(true);
    getClientList();
    getTotalRows();
  };
  const clearSearch = () => {
    sessionStorage.removeItem("searchBy");
    sessionStorage.removeItem("searchKey");
    sessionStorage.removeItem("searched", searched);
    sessionStorage.removeItem("currentPath");
    setSearchBy("Quotation Number");
    setSearchKey("");
    setFrom(null);
    setTo(null);
    setSearched(false); 
  };  

  useEffect(() => {
    getUsers();
    getClients();
  }, []);
  
  useEffect(() => {
      getClientList();
      getTotalRows();
  }, [rowsPerPage, page]);

  useEffect(() => {
    if(searched==false){
      getClientList();
      getTotalRows();
    }
    if (searched) {
      setPage(0);
    }
  }, [searched]);

  const initiateQuotationList = () => {
    var rowDaata = [];
    for (let i = 0; i < quotationList.length; i++) {
      rowDaata.push(
        createData(
          quotationList[i].id,
          quotationList[i].companyName,
          quotationList[i].address,
          quotationList[i].referenceNumber,
          quotationList[i].date
            ? moment(new Date(quotationList[i].date)).format("DD-MM-YYYY")
            : "",
          quotationList[i].totalAmount,
          quotationList[i].quotationNumber,
          quotationList[i].clientId,
          quotationList[i].subject,
          quotationList[i].equipmentCollection,
          quotationList[i].equipmentDelivery,
          quotationList[i].conveyanceCharges,
          quotationList[i].courierCharges,
          quotationList[i].enquiryDate,
          quotationList[i].enquiryId,
          quotationList[i].createdBy
        )
      );
    }
    setQuatationData(rowDaata);
  };
  useEffect(() => {
    initiateQuotationList();
  }, [quotationList]);
  
  const filterSection = () => (
    <Grid
      container
      spacing={2}
      // justifyContent="flex-end"
      alignItems="center"
      style={{ padding: "5px 0px" }}
    >
      <Grid item xs={6} sm={3} md={1} lg={1.5}id="quotationlist_datefrom">
      <LocalizationProvider dateAdapter={AdapterDateFns} classNames="mr-2">
            <DatePicker
            className="Listinputcss"
              slotProps={{ textField: { size: "small", fullWidth: true } }}
              label="From"
              value={from ? new Date(from) : ""}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              onChange={(newValue) => {
                setFrom(newValue);
                setSearched(false);
              }}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </LocalizationProvider>
      </Grid>
      <Grid item xs={6} sm={3} md={1} lg={1.5}id="quotationlist_dateto">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
            className="Listinputcss"
              slotProps={{ textField: { size: "small", fullWidth: true } }}
              label="To"
              value={to ? new Date(to) : ""}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              onChange={(newValue) => {
                setTo(newValue);
                setSearched(false);
              }}
              renderInput={(params) => <TextField {...params} size="small" />}
              style={{ marginRight: "20px" }}
            />
          </LocalizationProvider>
      </Grid>
      <Grid item xs={6} sm={6} md={2} lg={2.5} >
      <Autocomplete
          sx={{
            m: 0,
            minWidth: 180,
          }}
          className="Listinputcss"
          size="small"
          id="quotationlist_searchby"
          value={searchBy}
          options={[
            { key: "companyName", label: "Client" },
            { key: "referenceNumber", label: "Reference Number" },
            { key: "quotationNumber", label: "Quotation Number" },
          ]}
          renderInput={(params) => <TextField {...params} label="Search By" />}
          onInputChange={(event, newInputValue) => {
            setSearchBy(newInputValue);
            setSearched(false);
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={2} lg={2.5}>
      <TextField
      style={{ width: "100%" }}
      className="Listinputcss"
          id="quotationlist_searchbytext"
          label={"enter " + searchBy}
          size="small"
          variant="outlined"
          value={searchKey}
          inputProps={{
            autoComplete: 'off',
          }}
          onChange={(e) => {
            setSearchKey(e.target.value)
            setSearched(false);
          }}
        />
      </Grid>
      <Grid item xs={6} sm={6} md={1} lg={1} textAlign={"left"}>
      <Button
          id="quotationlist_searchbtn"
          variant="contained"
          size="small"
          color={searched ? "error" : "primary"}
          onClick={() => {
            searched ? clearSearch() : search();
          }}
        >
          {searched ? <RestartAltIcon /> : <SearchIcon />}
        </Button>
      </Grid>
      <Grid item xs={6} sm={12} md={2} lg={2} textAlign={"left"}>
      <ExcelDownload
          finalData={quotationData}
          notNeededColumn={notNeededColumn}
        />
      </Grid>
    </Grid>
  );

  const filterModal = () => {
    return (
      <Modal open={filterModalOpen} size="md">
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "calc(100% - 30%)",
            backgroundColor: "white",
            // border: "2px solid #000",
            boxShadow: 24,
            padding: "10px",
          }}
        >
          <Button
            variant="contained"
            size="small"
            color="error"
            style={{ float: "right" }}
            onClick={handleFilterClose}
          >
            X
          </Button>
          {filterSection()}
        </Box>
      </Modal>
    );
  };

  return (
    <EditWrap>
       {filterModal()}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Toolbar style={{ padding: "0px" }}>
          <Button
            id="quotationlist_createbtn"
            variant="contained"
            size="small"
            component={Link}
            to="/quotation/createQuotation"
          >
            <b>CREATE quotation</b>
          </Button>
        </Toolbar>
        <Box sx={{ display: "flex", gap: "0rem" }}>
              <Button
                style={{ display: isMobile ? "block" : "none" }}
                onClick={handleFilterOpen}
              >
                <FilterAltIcon />
              </Button>
        <Tooltip title="User Guide" placement="top-start">
          <Button
            onClick={() => {
              quotationListGuides.drive();
            }}
          >
            <HelpIcon />
          </Button>
        </Tooltip>
        </Box>
      </div>
      {!isMobile && filterSection()}
      {selected.length > 0 && (
        <Box sx={{textAlign:"left", margin:"10px 0px"}}>
          <Button
          variant="contained"
          size="small"
          color={"error"}
          onClick={() => {
            if (window.confirm("Really want to delete quotation?")){
              submitSiteDelete(selected);
            } 
          }}
          >Multiple Delete</Button>
        </Box>
      )}
    <TableContainer>
      <table
        stickyHeader
        aria-label="sticky table"
        size="small"
        id="quotationlist_table"
        width="100%"
      >
        <TableHead>
          <TableRow>
          {(editAccess?.includes(2) || editAccess?.includes(0)) && (
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={selected.length > 0 && selected.length < quotationData?.length}
                checked={selected.length === quotationData?.length}
                onChange={(event) => {
                  if (event.target.checked) {
                    setSelected(quotationData?.map((row) => row.id));
                  } else {
                    setSelected([]);
                  }
                }}
              />
            </TableCell>
            )}
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                <b>{column.label}</b>
              </TableCell>
            ))}
            <TableCell>
              <b>Actions</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {quotationData?.length > 0 ? (
            quotationData.map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={index} key={row.code}>
                  {(editAccess?.includes(2) || editAccess?.includes(0)) && (
                   <TableCell padding="checkbox">
                    <Checkbox
                      checked={isSelected(row.id)}
                      onChange={() => handleSelectRow(row.id)}  
                    />
                  </TableCell>
                   )}
                  {columns.map((column) => {
                    let value;
                    if (column.id === "createdBy") {
                      let userObject = users.filter(
                        (e) => e.id === row[column.id]
                      );
                      value = row[column.id] ? userObject[0]?.userName : null;
                    } else if (column.id === "clientId") {
                      value = (() => {
                        if (row[column.id]) {
                          const filterClient = clients.filter(
                            (e) => e.id == row[column.id]
                          )[0]?.contact;
                          const parsedData = filterClient
                            ? JSON.parse(filterClient)?.[0]
                            : {};
                          return (
                            <>
                              <h6 style={{ fontSize: "1em" }}>
                                <span style={{ fontWeight: "bold" }}>
                                  Name:
                                </span>{" "}
                                &nbsp;{parsedData?.contactPersonName}
                              </h6>
                              <h6 style={{ fontSize: "1em" }}>
                                <span style={{ fontWeight: "bold" }}>
                                  Email:
                                </span>{" "}
                                &nbsp;{parsedData?.emailId}
                              </h6>
                              <h5 style={{ fontSize: "1em" }}>
                                <span style={{ fontWeight: "bold" }}>
                                  Contact:
                                </span>{" "}
                                &nbsp;{parsedData?.contact}
                              </h5>
                            </>
                          );
                        }
                        return null;
                      })();
                    } else {
                      value = row[column.id];
                    }

                    if (column.id == "id") {
                      value = page * rowsPerPage + (index + 1);
                    }
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}
                  <TableCell>
                    <ButtonGroup size="small" aria-label="small button group">
                      <Tooltip title="Edit Quotation" placement="top-start">
                        <Button
                          id="quotationlist_edit"
                          component={Link}
                          to={`/quotation/editQuotation/${row.clientId}/${(
                            row.quotationNumber || ""
                          ).replaceAll("/", "_")}`}
                        >
                          <PreviewIcon />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Create FollowUp" placement="top-start">
                        <Button
                          id="quotationlist_followup"
                          component={Link}
                          to={`/quotation/quotationFollowUp/${row.id}`}
                        >
                          <BookmarkIcon />
                        </Button>
                      </Tooltip>
                      {showComponent("You really want to create invoice") && (
                        <Tooltip title="Create Invoice" placement="top-start">
                          <Button
                            id="quotationlist_createInvoice"
                            component={Link}
                            to={`/Invoice/createInvoice/${row.clientId}/${(
                              row.quotationNumber || ""
                            ).replaceAll("/", "_")}`}
                            onClick={(e) => {
                              if (
                                !window.confirm(
                                  "You really want to create invoice?"
                                )
                              ) {
                                e.preventDefault();
                              }
                            }}
                          >
                            <ReceiptLongIcon />
                          </Button>
                        </Tooltip>
                      )}

                      <Tooltip title="Send Email" placement="top-start">
                        <Button
                          id="quotationlist_mail"
                          onClick={() => {
                            SetSelectedColumn(row);
                            handleOpen();
                          }}
                        >
                          <EmailIcon />
                        </Button>
                      </Tooltip>

                      <Tooltip title="Print Quotation" placement="top-start">
                        <Button
                          id="quotationlist_download"
                          component={Link}
                          to={`/quotation/viewQuotation/${row.clientId}/${(
                            row.quotationNumber || ""
                          ).replaceAll("/", "_")}`}
                        >
                          <PrintIcon />
                        </Button>
                      </Tooltip>

                      {showComponent("delete") && (
                        <Tooltip title="Delete Quotation" placement="top-start">
                          <Button
                            id="quotationlist_delete"
                            
                            disabled={!(editAccess?.includes(2) || editAccess?.includes(0))}
                            onClick={(e) => {
                              if (
                                window.confirm(
                                  "Really want to delete quotation?"
                                )
                              ) {
                                submitSiteDelete(row.id);
                              }
                            }}
                          >
                            <DeleteIcon style={{ color:!(editAccess?.includes(2) || editAccess?.includes(0)) ? 'lightgray' : '#dc3545' }} />
                          </Button>
                        </Tooltip>
                      )}
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length + 1} align="center">
               <h6><b>Data Not Found</b></h6>
              </TableCell>
            </TableRow>
          )} 
        </TableBody>
        <RenderEmailModal
                open={open}
                handleClose={handleClose}
                selectedColumn={selectedColumn}
                refresh={refresh}
                formType={"quotation"}
              />
      </table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100, { label: "All", value: -1 }]}
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        component="div"
        colSpan={3}
        SelectProps={{
          inputProps: {
            "aria-label": "rows per page",
          },
          native: true,
        }}
        ActionsComponent={TablePaginationActions}
      />
      <ToastContainer />
      {errormodalIsOpen && (
        <ErrorModal
          errormodalIsOpen={errormodalIsOpen}
          closeErrorModal={closeErrorModal}
          errorMessage={errorMessage}
        />
      )}
    </TableContainer>
    </EditWrap>
  );
}
