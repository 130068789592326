var metric, imperial;

metric = {
  Pa: {
    name: {
      singular: "pascal",
      plural: "pascals",
    },
    to_anchor: 1 / 1000,
  },
  kPa: {
    name: {
      singular: "kilopascal",
      plural: "kilopascals",
    },
    to_anchor: 1,
  },
  MPa: {
    name: {
      singular: "megapascal",
      plural: "megapascals",
    },
    to_anchor: 1000,
  },
  hPa: {
    name: {
      singular: "hectopascal",
      plural: "hectopascals",
    },
    to_anchor: 1 / 10,
  },
  bar: {
    name: {
      singular: "bar",
      plural: "bar",
    },
    to_anchor: 100,
  },
  mbar: {
    name: {
      singular: "millibar",
      plural: "millibar",
    },
    to_anchor: 1 / 10,
  },

  torr: {
    name: {
      singular: "torr",
      plural: "torr",
    },
    to_anchor: 101325 / 760000,
  },
  "kg/cm²": {
    name: {
      singular: "Kg_cm_sq",
      plural: "Kg_cm_sq",
    },
    to_anchor: 98.0665,
  },
  mmHg: {
    name: {
      singulat: "Millimeters of mercury",
      plural: " Millimeters of mercury",
    },
    to_anchor: 0.133322,
  },
  inHg: {
    name: {
      singular: "Inches of mercury",
      plural: "Inches of mercury",
    },
    to_anchor: 3.386389e3,
  },
  mmWC: {
    name: {
      singular: "Millimeters of water column",
      plural: "Millimeters of water column",
    },
    to_anchor: 0.00980665,
  },
  "mmH₂O": {
    name: {
      singular: "millimeter of water column",
      plural: "millimeters of water column",
    },
    to_anchor: 0.00980665,
  },
};

imperial = {
  psi: {
    name: {
      singular: "pound per square inch",
      plural: "pounds per square inch",
    },
    to_anchor: 1 / 1000,
  },
  ksi: {
    name: {
      singular: "kilopound per square inch",
      plural: "kilopound per square inch",
    },
    to_anchor: 1,
  },
  mmhg: {
    name: {
      singulat: "Millimeters of mercury",
      plural: " Millimeters of mercury",
    },
    to_anchor: 1 / 51715.075480416,
  }
};

module.exports = {
  metric: metric,
  imperial: imperial,
  _anchors: {
    metric: {
      unit: "kPa",
      ratio: 0.00014503768078,
    },
    imperial: {
      unit: "psi",
      ratio: 1 / 0.00014503768078,
    },
  },
};
