import React, { useEffect } from "react";
import {
  Grid,
  Paper,
  Toolbar,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Autocomplete,
  FormControlLabel,
  FormControl,
  FormLabel,
  TextareaAutosize,
  Stack,
  TableContainer,
} from "@mui/material";
import html2pdf from "html2pdf.js";
import html2canvas from "html2canvas";
import UnitechHeader from "../image/certificate_header.png";
import UnitechFooter from "../image/certificate_footer.png";
import { toPng } from "html-to-image";
import moment from "moment";
import { BASE_URL } from "../../global";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router-dom";
import DownloadPdfLoaderModal from "../../utils/components/downloadPdfLoader";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import EditWrap from "../commonComponent/editWrap";

const ComplaintPrint = () => {
  const [complainData, setcomplainData] = React.useState([]);
  const params = useParams();
  const [users, setUsers] = React.useState([]);
  const printComponentRef = React.useRef();
  const [letterHead, setLetterHead] = React.useState(true);
  const [electronicSign, setElectronicSign] = React.useState(true);
  const [downloadPdfloading, setDownlaodPdfLoading] =React.useState(false);


  const getComplaint = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + `customerComplaint/${params.id}`)
      .then((res) => {
        setcomplainData(res.data);
        // toast("Customer Complaint created successfully !");
      })
      .catch((error) => {
        if (error.message !== "request_aborted") {
            toast.error("Something Went Wrong!");
          }
      });
  };

  // api calls
  function getUsers() {
    const data = {
      query: "SELECT id,userName FROM users",
    };
    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then(async (res) => {
        setUsers(res.data);
      })
      .catch((err) => {
        console.log("clients data fetching error: ", err);
      });
  }
  let userObject = users.filter((e) => e.id === complainData?.[0]?.createdBy);
  const createdByValue = complainData?.[0]?.createdBy
    ? userObject[0]?.userName
    : null;

///Download PDF

const generateDynamicHeader = async (
  ulrNumber,
  certificateNumber,
  pageNo
) => {
  let htmlString = `    
  <div style="padding:30px;">
    <table style="width:100%;height: 30px;">
      <tr>
        ${
          ulrNumber
            ? `<td style="padding:5px;font-family: Calibri, sans-serif;line-height:25px;">
          <b style="font-size:20px;">Document No. </b> <br />
          <span style="font-size:19px;">${ulrNumber}</span>
        </td>`
            : ""
        }
        <td style="padding:5px;font-family: Calibri, sans-serif;line-height:25px;">
          <b style="font-size:20px;">Issue No. | Date </b>
          <br>
          <span style="font-size:19px;">${certificateNumber}</span>
        </td>
              <td style="padding:5px;font-family: Calibri, sans-serif;line-height:25px;">
          <b style="font-size:20px;">Revision No. | Date  </b>
          <br>
          <span style="font-size:19px;">${certificateNumber}</span>
        </td>
        <td style="padding:5px;font-family: Calibri, sans-serif;line-height:25px;">
          <b style="font-size:20px;">Page Number</b>
          <br>
          <span style="font-size:19px;">${pageNo}</span>
        </td>
      </tr>
    </table>
  </div>
  `;
  let htmlNode = document.getElementById("header-section");
  htmlNode.innerHTML = htmlString;
  let image = await toPng(htmlNode, { quality: 1, pixelRatio: 3 });
  const img = new Image();
  img.src = image;
  htmlNode.innerHTML = "";
  return img;
};
const generateLetterHeadHeader = async () => {
  let htmlString = `
  <div>
    <img src=${UnitechHeader} style="width:100%; height:150px;"/>
  </div>`;
  let div = document.createElement("div");
    div.innerHTML = htmlString;
    document.body.appendChild(div);
    let canvas = await html2canvas(div, { scale: 1.3 });
    const img = new Image();
    img.src = canvas.toDataURL("image/jpeg", 0.90);
    document.body.removeChild(div);
    return img;
};

const generateLetterHeadFooter = async () => {
  let htmlString = `
  <div  style="padding:30px;">
  <table style="width:100%;height: 30px;">
  <thead>
    <tr>
      <th style="font-weight: bold;">Prepared & Authorized By</th>
      <th style="font-weight: bold;">${createdByValue?.toUpperCase()}  
      ${complainData?.[0]?.authorizedBySign ? `<img src="${complainData[0].authorizedBySign}" alt="Signature" style="width: 100px; height: 50px; object-fit: cover;" />` : ''}
      </th>
      <th style="font-weight: bold;">Date: ${moment(new Date()).format("DD/MM/YYYY")}</th>
    </tr>
  </thead>
</table>

<table style="margin-top: 10px;width:100%;height: 30px;">
  <thead>
    <tr>
      <th style="font-weight: bold;">Prepared & Issued By</th>
      <th style="font-weight: bold;">Director & Quality Manager</th>
      <th style="font-weight: bold;">Reviewed & Approved By</th>
      <th style="font-weight: bold;">Managing Director</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td style="font-weight: bold;">Signature</td>
      <td style="font-weight: bold;"> 
      ${complainData?.[0]?.preparedBySign ? `<img src="${complainData[0].preparedBySign}" alt="Signature" style="width: 100px; height: 50px; object-fit: cover;" />` : ''}
      </td>
      <td style="font-weight: bold;">Signature</td>
      <td style="font-weight: bold;">
      ${complainData?.[0]?.approvedBySign ? `<img src="${complainData[0].approvedBySign}" alt="Signature" style="width: 100px; height: 50px; object-fit: cover;" />` : ''}

      </td>
    </tr>
    <tr>
      <td style="font-weight: bold;">Date</td>
      <td style="font-weight: bold;">${moment(new Date()).format("DD/MM/YYYY")}</td>
      <td style="font-weight: bold;">Date</td>
      <td style="font-weight: bold;">${moment(new Date()).format("DD/MM/YYYY")}</td>
    </tr>
  </tbody>
</table>
  </div>`;
  let htmlNode = document.getElementById("header-section");
  htmlNode.innerHTML = htmlString;
  let image = await toPng(htmlNode, { quality: 1, pixelRatio: 3 });
  const img = new Image();
  img.src = image;
  htmlNode.innerHTML = "";
  return img;
};
const generatePDF = async () => {
  setDownlaodPdfLoading(true);
    try{
  const element = printComponentRef.current;
  let pdf = await html2pdf()
    .from(element)
    .set({
      margin: [140, 1, 165, 1],
      filename: `AllCertificate.pdf`,
      // pagebreak: {
      //   mode: ['avoid-all', 'css', 'legacy'],
      // },
      pagebreak: { after: "section", mode: ["css", "legacy"] },
      html2canvas: {
        dpi: 192,
        scale: 2.5,
        letterRendering: true,
        useCORS: true,
      },
      image: { type: 'png', quality: 0.4 },
      jsPDF: {
        orientation: "portrait",
        unit: "pt",
        format: "a4",
        compress: true,
      },
    })
    .toPdf()
    .get("pdf");

  const totalPages = pdf.internal.getNumberOfPages();
  for (let i = 1; i <= totalPages; i++) {
    pdf.setPage(i);
    pdf.setFontSize(9);
    pdf.setTextColor(0, 0, 0);
    pdf.setFont("Courier");

    let ulrNumber = "ABC123456";
    let certificateNumber = "361";
    let calibratedby = "BFI";
    let approvedby = "BFI123";
    let calibrationDate = "2023-09-21T03:03:29.000Z";

    let image = await generateDynamicHeader(ulrNumber, certificateNumber, i);
    pdf.addImage(image, 0, 70, 595, 80);

    // image = await generateDynamicFooter(
    //   calibratedby,
    //   approvedby,
    //   calibrationDate
    // );
    // pdf.addImage(image, 0, 675, 595, 100);

    if (letterHead) {
      let image = await generateLetterHeadHeader();

      pdf.addImage(image, 0, 0, 595, 70);

      image = await generateLetterHeadFooter();
      pdf.addImage(image, 0, 702, 595, 140);
    }
  }

  pdf.save();
  setDownlaodPdfLoading(false);
} catch (error) {
  console.error('Error generating PDF:', error);
  setDownlaodPdfLoading(false);
}
};





  useEffect(() => {
    getComplaint();
    getUsers();
  }, []);

  return (
    <EditWrap>
            <Grid container spacing={2} align="left" style={{ display: "flex" }}>
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={[
              { id: 1, label: "With Letter Head" },
              { id: 2, label: "Without Letter Head" },
            ]}
            renderInput={(params) => (
              <TextField {...params} label="letterHead" />
            )}
            onInputChange={(event, newInputValue) => {
              if (
                newInputValue === "With Letter Head" ||
                newInputValue === ""
              ) {
                setLetterHead(true);
              } else if (newInputValue === "Without Letter Head") {
                setLetterHead(false);
              }
              // newInputValue == "Approve" ? setEnableDisable() : setEnableDisable(4+':'+result.toString());
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={[
              { id: 1, label: "With Electronically Signed" },
              { id: 2, label: "Without  Electronically Signed" },
            ]}
            renderInput={(params) => (
              <TextField {...params} label="Electronically Signed" />
            )}
            onInputChange={(event, newInputValue) => {
              if (
                newInputValue === "With Electronically Signed" ||
                newInputValue === ""
              ) {
                setElectronicSign(true);
              } else if (newInputValue === "Without  Electronically Signed") {
                setElectronicSign(false);
              }
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Button
            variant="contained"
            size="small"
            sx={{ ml: 3 }}
            onClick={() => {
              generatePDF();
            }}
          >
            Download PDF
          </Button>
        </Grid>
      </Grid>
      <div
       ref={printComponentRef}
        style={{
          width: "95%",
          border: "1px solid black",
          textAlign: "center",
          margin: "10px auto",
          padding: "10px",
        }}
      >
        <Grid container spacing={2}>
          <Grid md={12} className="font-link" align="center">
            <h5 style={{ color: "#000066", margin: "10px 0px" }}>
              <b>CUSTOMER COMPLAINT</b>
            </h5>
          </Grid>
        </Grid>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableBody>
              <TableRow>
                <TableCell
                  align="left"
                  style={{
                    backgroundColor: "#D9D9D9",
                    fontWeight: "600",
                    width: "22%",
                  }}
                >
                  Customer Complaint Number
                </TableCell>
                <TableCell align="left" style={{ width: "28%" }}>
                  {complainData?.[0]?.id}
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    backgroundColor: "#D9D9D9",
                    fontWeight: "600",
                    width: "22%",
                  }}
                >
                  Date of Complaint Received
                </TableCell>
                <TableCell align="left" style={{ width: "28%" }}>
                  {moment(complainData?.[0]?.date).format("DD/MM/YYYY")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  align="left"
                  style={{
                    backgroundColor: "#D9D9D9",
                    fontWeight: "600",
                    width: "22%",
                  }}
                >
                  Customer Details(Name)
                </TableCell>
                <TableCell align="left" style={{ width: "28%" }}>
                  {complainData?.[0]?.customerId}
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    backgroundColor: "#D9D9D9",
                    fontWeight: "600",
                    width: "22%",
                  }}
                >
                  Customer Details(Number)
                </TableCell>
                <TableCell align="left" style={{ width: "28%" }}>
                  {complainData?.[0]?.customerId}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell
                  align="left"
                  style={{
                    backgroundColor: "#D9D9D9",
                    fontWeight: "600",
                    width: "22%",
                  }}
                >
                  Date of Closure of Complaint
                </TableCell>
                <TableCell align="left" style={{ width: "28%" }}>
               
                  {moment(complainData?.[0]?.closureDate).format("DD/MM/YYYY")}
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    backgroundColor: "#D9D9D9",
                    fontWeight: "600",
                    width: "22%",
                  }}
                >
                  Description of Complaint
                </TableCell>
                <TableCell align="left" style={{ width: "28%" }}>
                  {complainData?.[0]?.description}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  align="left"
                  style={{
                    backgroundColor: "#D9D9D9",
                    fontWeight: "600",
                  }}
                >
                  Complaint Acknowledged by Technical/Quality Manager
                </TableCell>
                <TableCell align="left">
                  {complainData?.[0]?.acknowledgedBy}
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    backgroundColor: "#D9D9D9",
                    fontWeight: "600",
                  }}
                >
                  Root Cause Analysis
                </TableCell>
                <TableCell align="left">
                  {complainData?.[0]?.rootCauseAnalysis}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  align="left"
                  style={{
                    backgroundColor: "#D9D9D9",
                    fontWeight: "600",
                  }}
                >
                  Correction
                </TableCell>
                <TableCell align="left">
                  {complainData?.[0]?.correction}
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    backgroundColor: "#D9D9D9",
                    fontWeight: "600",
                  }}
                >
                  Corrective Action
                </TableCell>
                <TableCell align="left">
                  {complainData?.[0]?.correctiveAction}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  align="left"
                  style={{
                    backgroundColor: "#D9D9D9",
                    fontWeight: "600",
                  }}
                >
                  Target
                </TableCell>
                <TableCell align="left">{complainData?.[0]?.target}</TableCell>
                <TableCell
                  align="left"
                  style={{
                    fontWeight: "600",
                    backgroundColor: "#D9D9D9",
                    borderRight: "none",
                  }}
                >
                  Remarks (if any)
                </TableCell>
                <TableCell align="left">{complainData?.[0]?.remark}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  align="left"
                  style={{
                    backgroundColor: "#D9D9D9",
                    fontWeight: "600",
                  }}
                >
                  Responsibility
                </TableCell>
                <TableCell align="left">
                  {complainData?.[0]?.responsibility}
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    backgroundColor: "#D9D9D9",
                    fontWeight: "600",
                  }}
                >
                  Complaint Status
                </TableCell>
                <TableCell align="left">{complainData?.[0]?.status}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell
                  align="left"
                  style={{
                    backgroundColor: "#D9D9D9",
                    fontWeight: "600",
                  }}
                >
                  Created By
                </TableCell>
                <TableCell align="left">
                {createdByValue?.toUpperCase()}
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    backgroundColor: "#D9D9D9",
                    fontWeight: "600",
                  }}
                >
                  Approved By
                </TableCell>
                <TableCell align="left">
                 {createdByValue?.toUpperCase()}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <div style={{ width: "100%" }} id="header-section"></div>
      </div>
      {downloadPdfloading && <DownloadPdfLoaderModal loading={downloadPdfloading} name={"Customer Complaint"} />}
    </EditWrap>
  );
};

export default ComplaintPrint;
