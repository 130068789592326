import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Paper from "@mui/material/Paper";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import Toolbar from "@mui/material/Toolbar";
import { Link, useLocation } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import moment from "moment";
import { TextField, Autocomplete, Table, Modal, useMediaQuery, Grid } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import IconButton from "@mui/material/IconButton";
import { showComponent } from "../../helper/helpers";
import HelpIcon from "@mui/icons-material/Help";
import "../../helper/helper.css";
import { masterDatasheetTemplateListGuide } from "./guide";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";
import ListWrap from "../../commonComponent/listWrap";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");


const columns = [
  { id: "id", label: "Sr. No.", minWidth: 20 },
  { id: "instrumentName", label: "Instrument Name", minWidth: 40 },
];

function createData(id, instrumentName, password, type, modules) {
  return {
    id,
    instrumentName,
    password,
    type: type == 1 ? "Admin" : "User",
    modules,
  };
}

export default function TemplateList(props) {
  const masterDatasheetTemplateListGuides = masterDatasheetTemplateListGuide();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const urlPath = useLocation();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [quotationList, setQuotationList] = React.useState([]);
  const [userData, setUserData] = React.useState([]);
  const [searchBy, setSearchBy] = React.useState(sessionStorage.getItem("searchBy") || "Instrument Name");
  const [searchKey, setSearchKey] = React.useState(sessionStorage.getItem("searchKey") || "");
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const [totalRows, setTotalRows] = React.useState(0);
  const _clientId = localStorage.getItem("clientId");
  const userType = localStorage.getItem("type");
  const [searched, setSearched] = React.useState(false);
  const [filterModalOpen, setFilterModalOpen] = React.useState(false);
  const [editAccess, setEditAccess] = React.useState(localStorage.getItem("editAccess")?.split(",").map(Number));

  const handleFilterOpen = () => setFilterModalOpen(true);
  const handleFilterClose = () => setFilterModalOpen(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const submitSiteDelete = (id) => {
    props.setLoader(true);
    console.log(id);
    let url = BASE_URL;
    axiosWithToken
      .delete(url + `datasheetTemplate/${id}`)
      .then((res) => {
        toast("Datasheet Template is deleted!");
        props.setLoader(false);
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        if (error.message !== "request_aborted") {
            toast.error("Something Went Wrong!");
          }
        // props.setLoader(false);
      });
  };

  var refresh = () => {
    window.location.reload(false);
  };

  function getClientList() {
    let whereConditions = "";
    let pagination = "";

    if (searchBy !== "" && searchKey !== "") {
      let colName = {
        "Instrument Name": "instrumentName",
      }[searchBy];
      whereConditions = `(${colName},like,~${searchKey}~)`;
    }

   
    if (rowsPerPage != -1) {
      pagination = `&_p=${Number(page)}&_size=${rowsPerPage}`;
    }

    whereConditions =
      whereConditions !== "" ? `&_where=${whereConditions}` : "";

    let fields = `_fields=id,instrumentName,instrumentId`;
    axiosWithToken
      .get(
        BASE_URL +
          `datasheetTemplate?${fields}${whereConditions}${pagination}&_sort=-id`
      )
      .then((res) => {
        let templates = res.data;
        var rowData = [];
        for (let i = 0; i < templates.length; i++) {
          rowData.push(
            createData(
              templates[i].id,
              templates[i].instrumentName,
              templates[i].instrumentId
            )
          );
        }
        setUserData(rowData);
      })
      .catch((err) => {
        console.log("clients data fetching error: ", err);
      });
  }

  // api calls
  function getTotalRows() {
    let whereConditions = "";
    let pagination = "";

    if (searchBy !== "" && searchKey !== "") {
      let colName = {
        "Instrument Name": "instrumentName",
      }[searchBy];
      whereConditions = `(${colName},like,~${searchKey}~)`;
    }

   
    if (rowsPerPage != -1) {
      pagination = `&_p=${Number(page)}&_size=${rowsPerPage}`;
    }

    whereConditions =
      whereConditions !== "" ? `&_where=${whereConditions}` : "";

    axiosWithToken
      .get(BASE_URL + `datasheetTemplate/count?${whereConditions}`)
      .then(async (res) => {
        setTotalRows(res.data[0]?.no_of_rows);
      })
      .catch((err) => {
        console.log("clients data fetching error: ", err);
      });
  }

  const TablePaginationActions = (props) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  };
  const storedSearchBy = sessionStorage.getItem("searched");
  useEffect(() => {
    if(storedSearchBy){
      setSearched(true);
    }
  }, [storedSearchBy]);

  const search = () => {
    sessionStorage.setItem("searchBy", searchBy);
    sessionStorage.setItem("searchKey", searchKey);
    sessionStorage.setItem("searched", "true");
    sessionStorage.setItem("currentPath", urlPath.pathname);
    setSearched(true);
    getClientList();
    getTotalRows();
  };
  const clearSearch = () => {
    sessionStorage.removeItem("searchBy");
    sessionStorage.removeItem("searchKey");
    sessionStorage.removeItem("searched", searched);
    sessionStorage.removeItem("currentPath");
    setSearchBy("Instrument Name");
    setSearchKey("");
    setSearched(false); 
  };

  
  useEffect(() => {
    getClientList();
    getTotalRows();
  }, [rowsPerPage, page]);

  useEffect(() => {
    if (searched==false) {
    getClientList();
    getTotalRows();
    }
    if (searched) {
      setPage(0);
    }
  }, [searched]);

  const filterSection = () => (
    <Grid
      container
      spacing={2}
      // justifyContent="flex-end"
      alignItems="center"
      style={{ padding: "5px 0px", marginBottom: "2px" }}
    >
      <Grid item xs={12} sm={5} md={2} lg={2}>
          <Autocomplete
            sx={{
              m: 0,
              minWidth: 180,
            }}
            size="small"
            className="Listinputcss"
            value={searchBy}
            id="template_master_list_searchby"
            options={[{ key: "instrumentName", label: "Instrument Name" }]}
            renderInput={(params) => (
              <TextField {...params} label="Search By" />
            )}
            onInputChange={(event, newInputValue) => {
              setSearchBy(newInputValue);
              setSearched(false);
            }}
          />
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={2}>
          <TextField
            id="template_master_list_searchbytext"
            label={"enter " + searchBy}
            size="small"
            className="Listinputcss"
            variant="outlined"
            value={searchKey}
            inputProps={{
              autoComplete: 'off',
            }}
            onChange={(e) => {
              setSearchKey(e.target.value)
              setSearched(false);
            }}
          />
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={1}>
          <Button
            id="template_master_list_searchbtn"
            variant="contained"
            size="small"
            color={searched ? "error" : "primary"}
            onClick={() => {
              searched ? clearSearch() : search();
            }}
          >
            {searched ? <RestartAltIcon /> : <SearchIcon />}
          </Button>
        </Grid>
      </Grid>
  );

  const filterModal = () => {
    return (
      <Modal open={filterModalOpen} size="md">
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "calc(100% - 30%)",
            backgroundColor: "white",
            // border: "2px solid #000",
            boxShadow: 24,
            padding: "10px",
          }}
        >
          <Button
            variant="contained"
            size="small"
            color="error"
            style={{ float: "right" }}
            onClick={handleFilterClose}
          >
            X
          </Button>
          {filterSection()}
        </Box>
      </Modal>
    );
  };


  return (
    <ListWrap>
      {filterModal()}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Toolbar style={{ padding: "0px" }}>
          {showComponent("add") && (
            <Button
              id="datasheet_template_list_createbtn"
              variant="contained"
              size="small"
              component={Link}
              to="/master/createDatasheetTemplate"
            >
              <b>create TEMPLATE</b>
            </Button>
          )}
        </Toolbar>
        <Box sx={{ display: "flex", gap: "0rem" }}>
          <Button
            style={{ display: isMobile ? "block" : "none" }}
            onClick={handleFilterOpen}
          >
            <FilterAltIcon />
          </Button>
        <Tooltip title="User Guide" placement="top-start">
          <Button
            onClick={() => {
              masterDatasheetTemplateListGuides.drive();
            }}
          >
            <HelpIcon />
          </Button>
        </Tooltip>
        </Box>
      </div>
      
      {!isMobile && filterSection()}
    <TableContainer>

        <Table
          stickyHeader
          aria-label="sticky table"
          size="small"
          id="datasheet_template_list_table"
          width="100%"
          sx={{ borderCollapse: "collapse" }}
        >
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  <b>{column.label}</b>
                </TableCell>
              ))}
              <TableCell>
                <b>Actions</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userData?.length > 0 ? (
              userData.map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      let value = row[column.id];
                      if (column.id == "id") {
                        value = page * rowsPerPage + (index + 1);
                      }
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                    <TableCell>
                      <ButtonGroup
                        size="small"
                        aria-label="small button group"
                        className="action-container"
                      >
                        {showComponent("show") && (
                          <Tooltip
                            title="Edit Datasheet Template"
                            placement="top-start"
                          >
                            <Button
                              id="datasheet_template_list_edit"
                              component={Link}
                              to={`/master/editDatasheetTemplate/${row.id}`}
                            >
                              <PreviewIcon />
                            </Button>
                          </Tooltip>
                        )}
                        {showComponent("delete") && (
                          <Tooltip
                            title="Delete Datasheet Template"
                            placement="top-start"
                          >
                            <Button
                              id="datasheet_template_list_delete"
                              disabled={!(editAccess?.includes(2) || editAccess?.includes(0))}
                              onClick={(e) => {
                                if (
                                  window.confirm(
                                    "Really want to delete Datasheet Template?"
                                  )
                                ) {
                                  submitSiteDelete(row.id);
                                }
                              }}
                            >
                              <DeleteIcon   style={{ color:!(editAccess?.includes(2) || editAccess?.includes(0)) ? 'lightgray' : '#dc3545' }} />
                            </Button>
                          </Tooltip>
                        )}
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length + 1} align="center">
                 <h6><b>Data Not Found</b></h6>
                </TableCell>
              </TableRow>
            )} 
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100, { label: "All", value: -1 }]}
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          component="div"
          colSpan={3}
          SelectProps={{
            inputProps: {
              "aria-label": "rows per page",
            },
            native: true,
          }}
          ActionsComponent={TablePaginationActions}
        />
        <ToastContainer />
      
    </TableContainer>
    </ListWrap>
  );
}
