import { evaluate, string } from "mathjs";
import { BASE_URL, MAX_COLS } from "../../../global";
import { customUnitConversion } from "../../../utils/components/customUnitConvertor";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";

const maxColumns = MAX_COLS;
const extraPrecisionSplitter = " + ";

const getStandardRangeValue = (
  columnKey,
  attribute, // e.g: .all
  standardRanges,
  standardRangeId,
  stdRangePref = 0,
  referenceData = null
) => {
  let standardRangeIds = standardRangeId?.split(",");

  if (attribute == "all") {
    standardRangeIds = standardRangeIds?.map((e) => Number(e.split(":")[1]));
  } else {
    standardRangeIds = [Number(standardRangeIds?.[0]?.split(":")[1])];
  }

  let ranges = standardRanges.filter((range) =>
    standardRangeIds.includes(range.rangeId)
  );

  let value = null;
  if (columnKey === "all") {
    value = ranges[0];
  } else if (attribute === "all") {
    value = ranges.map((e) => e?.[columnKey]?.split("#")[0] || 0).join(",");
  } else {
    value = ranges[0]?.[columnKey]?.split("#")[0] || 0;
  }

  return value;
};

function resolveFormulaConditions(
  formulas,
  referenceData,
  tableIndex,
  readingRow,
  unit_row,
  standardRanges
) {
  // Note: currently supporting operators: ==

  let selectedFormulas = {};
  Object.keys(formulas).forEach((column) => {
    if (typeof formulas?.[column] === "string") {
      selectedFormulas[column] = formulas[column];
    } else {
      Object.keys(formulas?.[column]).forEach((condition) => {
        if (condition != "default") {
          let subConditions = condition.split(" && ");

          let result = [];
          subConditions.forEach((subCondition) => {
            // validate condition
            let tokens = subCondition.split(" == ");
            // for single comparison
            if (tokens.length === 2) {
              let lhs = tokens[0].split(".");

              // find LHS value
              let lhs_val = null;

              if (lhs.includes("standardName")) {
                lhs_val = standardRanges
                  .map((standard) => standard.title.trim())
                  .join("||");
              } else if (lhs.includes("selectedStandardName")) {
                let selectedRangeIds = readingRow["standardRanges"]?.split(",") || [];

                let selectedStandardRanges = standardRanges.filter((range) =>
                  selectedRangeIds.includes(`${range.id}:${range.rangeId}`)
                );

                lhs_val = selectedStandardRanges?.map((standard) => standard.title.trim())
                  .join("||");
              } else if (lhs.length === 1) {
                lhs_val = lhs;
              } else if (lhs.length === 2) {
                if (lhs[0] === "ds") {
                  let colIndex = lhs[1].split("c")[1];
                  lhs_val = readingRow[`c${colIndex}`];
                } else {
                  lhs_val = referenceData[lhs[0]]?.[lhs[1]];
                }
              } else if (lhs.length === 3) {
                if (lhs[0] === "ds") {
                  if (unit_row.length < 1) return;

                  let colIndex = lhs[1].split("c")[1];
                  lhs_val = unit_row?.[`c${colIndex}`];
                  lhs_val = lhs_val?.split("_unit_")[1];
                } else {
                  lhs_val = referenceData[lhs[0]]?.[lhs[1]];
                  lhs_val = lhs_val?.split("||");
                  if (lhs[2] === "unit" && lhs_val) {
                    if (lhs_val?.length > 1) {
                      lhs_val = lhs_val[tableIndex]?.split("#")[1];
                    } else {
                      lhs_val = lhs_val[0]?.split("#")[1];
                    }
                  }
                }
              }

              // find RHS value
              let rhs =
                typeof tokens == "string" ? tokens[1].split(".") : tokens[1];
              let rhs_val = null;
              if (typeof rhs == "string") {
                rhs_val = rhs.trim();
              } else {
                if (rhs.length === 1) {
                  rhs_val = rhs[0];
                } else if (rhs.length === 2) {
                  rhs_val = referenceData[rhs[0]][rhs[1]];
                } else if (rhs.length === 3) {
                  rhs_val = referenceData[rhs[0]][rhs[1]];
                  if (rhs[2] === "unit") {
                    rhs_val = rhs_val.split(" ")[1];
                  }
                }
              }

              // compare LHS and RHS
              if (lhs_val?.includes("||") && lhs_val?.includes(rhs_val)) {
                selectedFormulas[column] = formulas[column][condition];
                result.push(true);
              } else if (lhs_val == rhs_val) {
                result.push(true);
              } else {
                result.push(false);
              }
            }
          });
          if (!result.includes(false)) {
            selectedFormulas[column] = formulas[column][condition];
          }
        }
      });
      if (!selectedFormulas[column]) {
        selectedFormulas[column] = formulas[column]["default"];
      }
    }
  });
  return selectedFormulas;
}

const resolveReferenceTableColumns = (
  formula,
  element,
  referenceData,
  standardRanges
) => {
  // 1. resolve standard ranges table cols;
  if (formula?.includes("standardRanges")) {
    let tokens = [...formula.matchAll(/standardRanges.[a-zA-Z.]+/g)];
    tokens = tokens.map(match => match[0]);
    tokens?.forEach((column) => {
      let value = getStandardRangeValue(
        column.split(".")?.[1],
        column.split(".")?.[2],
        standardRanges,
        element['standardRanges'],
        referenceData
      );
      formula = formula.replace(column, value);
    });
  }

  if (formula?.includes("srfInstrument")) {
    const keys = Object.keys(referenceData?.srfInstruments || {});
    const srfInstrumentKeys = keys.sort((a, b) => b.length - a.length)
    srfInstrumentKeys.forEach((key) => {
      if (formula.includes(key)) {
        let value = referenceData?.srfInstruments[key];
        value = String(value).split("#")[0]?.replaceAll("$", "");
        formula = formula.replaceAll(`srfInstrument.${key}`, Number(value) || 0);
      }
    });
  }

  return formula;
};

async function resolveFormulas(
  readingRows,
  datasheetObservedReadingFallbackValue,
  configs,
  datasheet,
  referenceData,
  standardRanges
) {
  let _originalConfig = configs;

  for (let j = 0; j < configs.length; j++) {
    let config = configs[j];
    let tableId = config.tableId;
    let original_formulas = config.formulas;
    let tableIndex = config.index;
    let unit_row = [];

    for (let i = 0; i < readingRows.length; i++) {
      let readingRow = readingRows[i];
      // skip reading if it is not for current table
      if (readingRow["tableId"] != tableId) continue;

      // skip for units
      if (String(readingRow["c1"])?.includes("_unit_")) {
        unit_row = readingRow;
        continue;
      }
      // skip header rows
      if (string(readingRow["c1"]).includes("_rh_")) continue;

      let formulas = resolveFormulaConditions(
        original_formulas,
        referenceData,
        tableIndex,
        readingRow,
        unit_row,
        standardRanges
      );

      for (let k = 0; k < Object.keys(formulas).length; k++) {
        let column = Object.keys(formulas)[k];

        let formula = formulas[column];
        let actualFormula = formula;
        if (readingRow["tableId"] === tableId) {
          // replace table columns with actual values
          formula = resolveReferenceTableColumns(
            formula,
            readingRow,
            referenceData,
            standardRanges
          );

          // replace datasheet fields with values
          let datasheetKeys = Object.keys(datasheet || {})?.sort((a, b) => b.length - a.length);
          datasheetKeys.forEach((key) => {
            if (formula.includes(key)) {
              let dsKey = datasheet[key];
              // pick 1 value from multi values if any
              dsKey = String(dsKey)?.replace("\n", "").split("||");
              dsKey = dsKey[tableIndex] || dsKey[0];

              // remove unit from dsKey if it contains
              dsKey = String(dsKey).split("#")[0];
              formula = formula.replaceAll(key, Number(dsKey) || 0);
            }
          });

          let level = 1;
          let isInit = true;
          do {
            // replace column representaitons with values
            for (let j = maxColumns - 1; j >= 0; j--) {
              let _formula = formula;
              if (formula?.includes("c" + j)) {
                _formula = formula.replaceAll("c" + j, readingRow["c" + j]);
              }
              if (isInit && formula !== _formula) level += 1;
              formula = _formula;
            }

            isInit = false;

            // resolve -- terms
            formula = formula.replaceAll("--", "+");

            // redefine math formulas
            for (const val of ["sqrt", "pow", "max", "min", "abs"]) {
              formula = formula.replaceAll(val, "Math." + val);
              // TODO: added quick for multile Math. in formula
              formula = formula.replaceAll("Math.Math.", "Math.");
            }

            // redefine custom math formulas
            if (formula.includes("std")) {
              // calculate math.std(c1,c2,c3) with math.std library function and replace it with result
              formula = evaluate(formula);
              formula = String(formula);
            }

            // miscellanious
            formula = formula.replaceAll(":", ",");
            formula = formula.replaceAll("$", "");

            // resolve custom functions
            if (formula.includes("avg")) {
              let token = formula.match(/avg\((.*?)\)/);
              token = token?.[1];
              let values = token?.split(",");
              let sum = 0;
              let count = 0;
              values.forEach((value) => {
                if (value != "" && Number(value) == value && value != "") {
                  sum += Number(value);
                  count += 1;
                }
              });
              if (count > 0) {
                let avg = sum / count;
                formula = formula.replace(`avg(${token})`, avg);
              } else {
                if (formula == `avg(${token})`) {
                  formula = formula.replace(`avg(${token})`, "");
                } else{
                  formula = formula.replace(`avg(${token})`, 0);
                }
              }

            }
            if (formula.includes("sum")) {
              let token = formula.match(/sum\((.*?)\)/);
              token = token?.[1];
              let values = token?.split(",");
              let sum = 0;
              values.forEach((value) => {
                if (value != "" && Number(value) == value) {
                  sum += Number(value);
                }
              });
              formula = formula.replace(`sum(${token})`, sum);
            }

            try {
              let res = Number(eval(formula));

              // custom Unit conversion, only supported under direct column assignments
              // current support:
              //  1. degree C to Ohm and vice versa
              // TODO: make it general
              const regex = /^[cC]\d+$/;
              if (regex.test(actualFormula)) {
                res = await customUnitConversion(
                  res,
                  unit_row[actualFormula]?.replace("_unit_", ""),
                  unit_row[column]?.replace("_unit_", ""),
                  readingRow, // current row
                  referenceData
                );
              }
              readingRow[column] = String(res)


              if (readingRow[column]?.includes("Infinity")) {
                readingRow[column] = "0";
              } else if (isNaN(readingRow[column])) {
                readingRow[column] = datasheetObservedReadingFallbackValue || "";
              }

              let hasDoller = false;
              if (readingRow[column]?.includes("$")) {
                readingRow[column] = readingRow[column]?.replaceAll("$", "");
                hasDoller = true;
              }
              let tmpRows = resolvePrecision(
                [readingRow],
                _originalConfig,
                datasheet,
                standardRanges
              );
              if (hasDoller) {
                readingRow[column] = "$" + tmpRows[0][column];
              } else {
                readingRow[column] = tmpRows[0][column];
              }
            } catch (err) {
              console.error("failed to process formula", err);
              readingRow[column] = datasheetObservedReadingFallbackValue || "";
            }
            level--;
          } while (level > 0);
        }
      }
    }
  }
  return readingRows;
}

const resolvePrecision = (
  readingRows,
  config,
  datasheet,
  standardRanges) => {
  config.forEach((config) => {
    let tableId = config.tableId;
    let tableIndex = config.index;
    let customPrecision = config.customPrecision;
    let formulaKeys = Object.keys(config?.formulas || {})


    for (const element of readingRows) {
      // skip for invalid cases
      if (element["tableId"] != tableId) continue;
      if (String(element['c1'])?.includes("_unit_")) continue;
      if (String(element['c1'])?.includes("_rh_")) continue;

      Object.keys(element).forEach(column => {
        // 1. if custom precision is defined then calculate precision accordingly
        if (customPrecision[column]) {
          // gather data of right side columns
          let r_value = String(customPrecision[column]).split(".");
          let extraPrecision = Number(
            r_value[0].split(extraPrecisionSplitter)[1] || 0
          );
          r_value[0] = r_value[0].split(extraPrecisionSplitter)[0];
          let rValue = 0;
          let rValueDecimalCount = 0;

          if (r_value[0] != 7) {
            if (r_value[0].includes("manual")) {
              rValueDecimalCount = Number(r_value[1]);
            } else {
              if (r_value[0].includes("ds")) {
                r_value = r_value[0]; //eg r_value: ds1.1, ds2.null
                rValue = element["c" + r_value?.split("ds")[1]?.split(".")?.[0]];
              } else {
                let rIndex = Number(r_value[0]);
                let stdRangePref = Number(r_value[1] || "0");
                if (r_value[0] == 6) {
                  rValue = [
                    datasheet.lc,
                    getStandardRangeValue(
                      "lc",
                      null,
                      standardRanges,
                      element['standardRanges'],
                      stdRangePref
                    ) || "0.0",
                  ];
                } else {
                  rValue = [
                    0,
                    0,
                    datasheet.lc,
                    getStandardRangeValue(
                      "lc",
                      null,
                      standardRanges,
                      element['standardRanges'],
                      stdRangePref
                    ) || 0,
                    datasheet.accuracy,
                  ][rIndex - 1];
                }
              }
              if (r_value[0] == 6) {
                rValue[0] = String(rValue[0])?.replace("\n", "").split("||");
                rValue[0] =
                  rValue[0]?.length > tableIndex
                    ? rValue[0][tableIndex]
                    : rValue[0][0];
                rValue =
                  (rValue[0].split(".")[1] || "").length >
                    (rValue[1].split(".")[1] || "").length
                    ? rValue[0]
                    : rValue[1];
              } else {
                // pick 1 value from multi values if any
                rValue = String(rValue)?.replace("\n", "").split("||");
                rValue =
                  rValue?.length > tableIndex ? rValue[tableIndex] : rValue[0];
              }

              // update reading col's value's precision according to soruce column
              rValueDecimalCount = 0;
              if (String(rValue).includes(".")) {
                rValueDecimalCount = String(rValue).split(".")[1];
                rValueDecimalCount = rValueDecimalCount.split("#")[0].length;
              }
            }
          }

          let value = String(element[column]).replaceAll("$", "");
          let fixedValue = null;
          if (value !== "" && !isNaN(value)) {
            if (r_value[0] == 7) {
              fixedValue = Number(value);
            } else {
              // TODO: temperory logic please test this with all scenarious , task: https://trello.com/c/juLnU4pH
              let res = Number(value);
              // var factor = Math.pow(10, rValueDecimalCount + extraPrecision);
              // var adjustedNum = res + 1 / (2 * factor);
              fixedValue = res.toFixed(rValueDecimalCount + extraPrecision);
            }
          } else {
            fixedValue = value;
          }
          element[column] = String(element[column]).replace(
            value,
            String(fixedValue)
          );
        } else if (formulaKeys.includes(column)) {
          // 2. else set MAX precision as per application config
          let value = String(element[column]).replaceAll("$", "");
          let fixedValue = null;
          if (value !== "" && !isNaN(value)) {
            // TODO: take 7 number from config, should not be static
            fixedValue = Number(value).toFixed(7)
          } else {
            fixedValue = value;
          }
          element[column] = String(element[column]).replace(
            value,
            String(fixedValue)
          );
        }
      })
    }
  });
  return readingRows;
};

// datasheet ====================
export async function prepareDatasheetReadings(props) {
  let {
    config,
    readings,
    datasheetObservedReadingFallbackValue,
    datasheet,
    standardRanges,
    referenceData,
  } = props;
  readings = await resolveFormulas(
    readings,
    datasheetObservedReadingFallbackValue,
    config,
    datasheet,
    referenceData,
    standardRanges
  );
  let res = resolvePrecision(
    readings,
    config,
    datasheet,
    standardRanges
  );

  return res;
}

// typeB ==============================
export function prepareTypeBValues(typeBConfiguration, datasheetReading) {
  let typeBValues = {};
  Object.keys(typeBConfiguration).forEach((key) => {
    // structure whould be {uncertaintyFactor : value, ...}
    typeBValues[typeBConfiguration[key]] = datasheetReading[key];
  });
  return JSON.stringify(typeBValues);
}

// generic ============================
export async function getDateTimeFromServer(time = false) {
  try {
    const url = `${BASE_URL}dynamic`;
    const query = `SELECT now() as date`;
    const response = await axiosWithToken.post(url, { query });
    let dateTime = new Date(response.data[0]?.date || null);
    if (time) {
      dateTime = {
        hour: dateTime.getHours(),
        minute: dateTime.getMinutes(),
        second: dateTime.getSeconds(),
      };
    }
    return dateTime;
  } catch (error) {
    console.error(error);
    return 0;
  }
}

export const withUnit = (val, unit) => {
  if (String(val).includes("_unit_") || String(val).includes("_unit_"))
    return val;
  return `${String(val)}#${String(unit).includes("_unit_") ? unit.split("_unit_")[1] : unit
    }`;
};
