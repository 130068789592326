import axiosWithToken from "../../../utils/components/axiosTokenConfig";
import { BASE_URL, SRF_ID_BASE } from "../../../global";
import { toast } from "react-toastify";
import { clearPreviousReadings } from "../helper";
import moment from "moment";

const processData = (settings, indicators, dispatch) => {
  let amd = false,
    calTypeSetting = false;

  let _settings = {};
  settings?.forEach((setting) => {
    _settings[setting.keyName] = setting.value;
    if (
      setting.keyName === "amendment" &&
      setting.value.toLowerCase() === "true"
    ) {
      amd = true;
    } else if (
      setting.keyName === "Calibration Type" &&
      setting.value.toLowerCase() === "true"
    ) {
      calTypeSetting = true;
    }
  });
  dispatch({
    field: "indicators",
    value: {
      ...indicators,
      isShowAmendment: amd,
      isCalTypeSetting: calTypeSetting,
    },
  });
  dispatch({
    field: "settings",
    value: _settings,
  });
};

export function fetchSettings(indicators, dispatch) {
  try {
    axiosWithToken
      .get(BASE_URL + `settings?_where=(status,eq,1)`)
      .then((res) => {
        processData(res.data, indicators, dispatch);
      });
  } catch (err) {
    if (err.message !== "request_aborted") {
      toast.error("Failed to fetch settings");
    }
  }
}

function prepareAndSetDatasheet(datasheetDetails, dispatch) {
  let range_low = [];
  let range_high = [];
  let ranges = datasheetDetails?.ranges?.split("||");
  ranges?.forEach((range) => {
    range_low.push((range.split("|")[0]?.match(/[+-\d\.]+/g) || [])[0]);
    range_high.push((range.split("|")[1]?.match(/[+-\d\.]+/g) || [])[0]);
  });

  // 2. calculate next due date if not present
  if (!datasheetDetails?.nextDueDate) {
    let calibrationDate = datasheetDetails.calibrationDate;

    let calibrationFrequency = datasheetDetails.calibrationFrequency;
    if (calibrationFrequency && calibrationDate && calibrationFrequency !== "Not Required") {
      datasheetDetails["nextDueDate"] = new Date(
        moment(calibrationDate)
          .add(calibrationFrequency?.split(" ")?.[0] || 0, "M")
          .format("YYYY-MM-DD")
      );
    } else {
      datasheetDetails["nextDueDate"] = null;
    }
  } else {
    datasheetDetails["nextDueDate"] = new Date(datasheetDetails.nextDueDate);
  }

  dispatch({
    field: "datasheetDetails",
    value: {
      ...datasheetDetails,
      range_low: range_low.join("||"),
      range_high: range_high.join("||"),
    },
  });
}

export async function fetchDatasheet(datasheetId, indicators, dispatch) {
  let url = BASE_URL;
  return axiosWithToken
    .get(
      url +
      `xjoin?_join=ds.datasheets,_j,ins.instruments,_j,cust.clients,_j,disc.discipline&_on1=(ds.instrumentId,eq,ins.id)&_on2=(cust.id,eq,ds.clientId)&_on3=(ins.disciplineId,eq,disc.id)&_fields=ds.lastModified,ds.amendment,ds.revisionNumber,ds.calibratedby,ds.datasheetApprovedBy,ds.clientId,cust.companyName,ins.instrumentName,ds.jobNumber,ds.requestedname,ds.datasheetImages,ds.id,ds.make,ds.dcNumber,ds.dcDate,ds.lc,ds.instrumentId,ds.deletedTableIds,ins.standardMasterIds,ds.ranges,ins.calibrationProcedureNo,ins.referenceStandards,ds.isaStandard,cust.address,ds.dcNumber,ds.calibrationDate,ds.dcDate,ds.nextDueDate,ds.receiptDate,ds.calProcRefNo,ds.mfgNo,ds.identificationNo,ds.startTemp,ds.middleTemp,ds.endTemp,ds.startLiquidTemp,ds.middleLiquidTemp,ds.endLiquidTemp,ds.additionalStandardIds,ds.DUCID,ds.calibrationType,ds.specificValues,ds.location,ds.serialNumber,ds.accuracy,ds.poNumber,ds.poDate,ds.dateOfIssue,ds.model,ds.ConOfDuc,ds.calPoint,ds.calMethod,ds.locationOfInstrument,ds.srnNo,ds.configuration,ds.startPressure,ds.middilePressure,ds.endPressure,ds.tableDisplayStatus,ds.startHumidity,ds.middleHumidity,ds.endHumidity,disc.startTemp,disc.endTemp,disc.startHumidity,disc.endHumidity,disc.id,disc.name,disc.remarks,ds.extraColumns,ds.readingsGroupByDetails,ds.serviceReqNumber,ds.calibrationReason,ds.calFrequency,ds.complianceStatus&_where=(ds.id,eq, ${datasheetId})`
    )
    .then((res) => {
      if (res.data.length > 0 && res.data[0] != null) {
        let data = res.data[0];
        let datasheetDetails = {
          id: datasheetId,
          jobNumber: data.ds_jobNumber,
          calibrationFrequency: data.ds_calFrequency || "",
          revisionNumber: data.ds_revisionNumber,
          clientId: data.ds_clientId,
          clientIdNumber: data.ds_clientId,
          clientName: data.cust_companyName,
          instrumentName: data.ins_instrumentName,
          requestedname: data.ds_requestedname
            ? data.ds_requestedname
            : "",
          instrumentId: data.ds_instrumentId,
          make: data.ds_make,
          lc: data.ds_lc,
          defaultRemark: data.disc_remarks || "",
          ranges: data.ds_ranges,
          dcDate: data.ds_dcDate ? data.ds_dcDate : "",
          dcNo: data.ds_dcNumber,
          deletedTableIds: data.ds_deletedTableIds,
          referenceStandards: data.ins_referenceStandards,
          calibrationProcedureNo: data.ins_calibrationProcedureNo,
          calibrationDate: data.ds_calibrationDate
            ? data.ds_calibrationDate
            : "",
          nextDueDate: data.ds_nextDueDate
            ? data.ds_nextDueDate
            : "",
          receiptDate: data.ds_receiptDate
            ? data.ds_receiptDate
            : "",
          mfgNo: data.ds_mfgNo,
          startTemp: data.ds_startTemp
            ? data.ds_startTemp
            : data.disc_startTemp,
          middleTemp: data.ds_middleTemp,
          endTemp: data.ds_endTemp
            ? data.ds_endTemp
            : data.disc_endTemp,
          startHumidity: data.ds_startHumidity
            ? data.ds_startHumidity
            : data.disc_startHumidity,
          middleHumidity: data.ds_middleHumidity,
          endHumidity: data.ds_endHumidity
            ? data.ds_endHumidity
            : data.disc_endHumidity,
          startLiquidTemp: data.ds_startLiquidTemp,
          middleLiquidTemp: data.ds_middleLiquidTemp,
          endLiquidTemp: data.ds_endLiquidTemp,
          identificationNo: data.ds_identificationNo,
          DUCID: data.ds_DUCID,
          location: data.ds_location == 1 ? "In House" : "Onsite",
          accuracy: data.ds_accuracy,
          serialNo: data.ds_serialNumber,
          poNo: data.ds_poNumber,
          poDate: data.ds_poDate,
          dateOfIssue: data.ds_dateOfIssue,
          model: data.ds_model,
          conOfDuc: data.ds_ConOfDuc,
          calPoint: data.ds_calPoint,
          calMethod: data.ds_calMethod,
          locationOfInstrument: data.ds_locationOfInstrument,
          configuration: JSON.parse(data.ds_configuration) || {},
          startPressure: data.ds_startPressure,
          middilePressure: data.ds_middilePressure,
          endPressure: data.ds_endPressure,
          disciplineId: data.disc_id,
          disciplineName: data.disc_name,
          tableDisplayStatus: data.ds_tableDisplayStatus,
          extraColumns: Object.entries(JSON.parse(data.ds_extraColumns)),
          amendmentHistory: data.ds_amendment,
          calibrationReason: data.ds_calibrationReason,
          calProcRefNo: data?.ds_calProcRefNo,
          isaStandard: data?.ds_isaStandard,
          calibrationType: data?.ds_calibrationType,
        };
        prepareAndSetDatasheet(datasheetDetails, dispatch);

        dispatch({
          field: "readingsGroupByDetails",
          value: JSON.parse(data?.ds_readingsGroupByDetails)
        })

        dispatch({
          field: "standardMasterDetails",
          value: { standardMasterIds: data?.ins_standardMasterIds },
        });

        dispatch({
          field: "datasheetImages",
          value: data.ds_datasheetImages?.split(",") || [],
        });

        dispatch({
          field: "indicators_complianceStatus",
          value: data?.ds_complianceStatus == 1 ? true : false,
        });

        // TODO: move both the below calls into separate function
        axiosWithToken
          .get(
            BASE_URL +
            `srfs?_fields=serviceReqNumber,id,entryDate,customerAnnextureFilePaths&_where=(serviceReqNumber,eq,${res.data[0]?.ds_serviceReqNumber})`
          )
          .then((res) => {
            dispatch({
              field: "srfDetails",
              value: {
                srfNumber: res.data[0]?.serviceReqNumber,
                entryDate: res.data[0]?.entryDate,
                customerAnnextureFilePaths:
                  res.data[0]?.customerAnnextureFilePaths,
                srfId: res.data[0]?.id,
              },
            });
          });

        if (res.data[0].ds_calibratedby) {
          axiosWithToken
            .get(BASE_URL + `users/${res.data[0].ds_calibratedby}`)
            .then((res) => {
              const userData = res.data[0];
              const calibratedByUsername = userData?.userName || "";
              const calibratedByImgSign = userData?.usersign || "";
              dispatch({
                field: "calibratedBy",
                value: calibratedByUsername,
              });
              dispatch({
                field: "calibratedByImgSign",
                value: calibratedByImgSign,
              });
            })
            .catch((err) => console.error("Error fetching user data:", err));
        }
        if (res.data[0].ds_datasheetApprovedBy) {
          axiosWithToken
            .get(BASE_URL + `users/${res.data[0].ds_datasheetApprovedBy}`)
            .then((res) => {
              const userData = res.data[0];
              const datasheetApprovedByUsername = userData?.userName || "";
              const datasheetApprovedByImgSign = userData?.usersign || "";
              dispatch({
                field: "datasheetApprovedBy",
                value: datasheetApprovedByUsername,
              });
              dispatch({
                field: "datasheetApprovedByImgSign",
                value: datasheetApprovedByImgSign,
              });
            })
            .catch((err) => console.error("Error fetching user data:", err));
        }
      }
    })
    .catch((err) => {
      console.error("datasheet data fetching error: ", err);
    });
}

export const fetchCertificateDetails = (datasheetId, dispatch) => {
  axiosWithToken
    .get(
      BASE_URL + `certificates/${datasheetId}?_fields=certificateNumber,ULRNo`
    )
    .then((res) => {
      dispatch({
        field: "certificateDetails",
        value: {
          certificateNumber: res.data[0]?.certificateNumber,
          ULRNo: res.data[0]?.ULRNo,
          serviceReqNumber: res.data[0]?.serviceReqNumber,
        },
      });
    });
};

export function fetchDatasheetStaticTables(
  instrumentId,
  deletedTableIds,
  setDerivedColumns,
  setTableConfig,
  dispatch,
  table_id
) {
  let url = BASE_URL;
  deletedTableIds = deletedTableIds?.split(",")?.map((id) => Number(id));
  let data_url = table_id
    ? `datasheetStaticTables/${table_id}`
    : `datasheetStaticTables?_where=(instrumentId,eq,${instrumentId})`;
  return axiosWithToken
    .get(url + data_url)
    .then((res) => {
      if (deletedTableIds) {
        res.data = res.data.filter((row) => !deletedTableIds.includes(row.id));
      }
      if (res.data.length > 0) {
        let tmp = {};
        let cMap = {};
        res.data.map((table) => {
          tmp[table.id] = {
            isUncertainty: table.isUncertainty,
            isVerticalUncertainty: table.isVerticalUncertainty,
            isAutomated: table.isAutomated,
            defaultValues: JSON.parse(table.defaultConfiguration)?.values,
          };
          cMap[table.id] = Object.keys(
            JSON.parse(table.defaultConfiguration).formulas
          );
        });

        // pre process res.data
        res.data = res.data.map((table) => {
          return {
            ...table,
            "defaultConfiguration": JSON.parse(table?.defaultConfiguration || null)
          }
        })

        dispatch({
          field: "staticTables",
          value: res.data,
        });
        setDerivedColumns({ ...cMap });
        setTableConfig(tmp);
      } else {
        dispatch({
          field: "staticTables",
          value: [],
        });
        setDerivedColumns({});
        setTableConfig({});
      }
    })
    .catch((err) => {
      console.error(
        "[ERROR] Failed to fetch datasheet static tables, error: ",
        err
      );
      return err;
    });
}

export function fetchCertificateStaticTables(
  instrumentId,
  deletedTableIds = "",
  dispatch
) {
  let url = BASE_URL;
  return axiosWithToken
    .get(
      url + `certificateStaticTables?_where=(instrumentId,eq,${instrumentId})`
    )
    .then((res) => {
      // pre process res.data
      res.data = res.data.map((table) => {
        return {
          ...table,
          "defaultConfiguration": JSON.parse(table?.defaultConfiguration || null)
        }
      })

      dispatch({
        field: "certificateStaticTables",
        value: res.data,
      });

      return res;
    })
    .catch((err) => {
      console.error("certificate static tables data fetching error: ", err);
      return err;
    });
}

export function fetchStaticReadingRows(
  datasheetId,
  dispatch,
  isAutoload = false,
  currentDatasheetId = 0,
  deletedTableIds = "",
  selectedMasterORNot = false,
) {
  let url = BASE_URL;

  // for static datasheet
  let datasheetReadingsQuery = `select * from datasheetStaticReadings where datasheetId = ${datasheetId}`;
  if (deletedTableIds) {
    datasheetReadingsQuery += ` and tableId not in(${deletedTableIds})`;
  }

  return Promise.all([
    axiosWithToken
      .post(BASE_URL + `dynamic`, { query: datasheetReadingsQuery })
      .then((res) => {
        // if isAutoload is true, then change datasheetId to currentDatasheetId, and set id to 0
        if (isAutoload) {
          res.data = res.data.map((e) => {
            e.datasheetId = currentDatasheetId;
            e.id = 0;
            return e;
          });
        }
        if (selectedMasterORNot) {
          res.data = res.data.map((data) => {
            return {
              ...data,
              standardRanges: null
            }
          })
        }
        dispatch({
          field: "datasheetStaticReadings",
          value: res.data,
        });

        // extraxt standardIds from standardRanges and set to selectedStandardIds context
        let standardIds = res.data.map((e) => e.standardRanges).filter(Boolean);

        const selectedStandardIds = [];

        standardIds.forEach((str) => {
          str.split(",").forEach((sub) => {
            const num = parseInt(sub.split(":")[0]);
            if (!selectedStandardIds.includes(num)) {
              selectedStandardIds.push(num);
            }
          });
        });

        dispatch({
          field: "selectedStandardIds",
          value: [...new Set(selectedStandardIds)],
        });
      })
      .catch((err) => {
        console.error(
          "[ERROR] Failed to load datasheet reading rows, error: ",
          err
        );
      })
  ]);
}

export async function fetchbeforeAutoloadJobNumber(datasheetId, datasheetDetails) {
  try {
    let queries = [];
    if (datasheetDetails?.DUCID && datasheetDetails?.ranges && datasheetDetails?.lc)
      queries = queries.concat([
        `datasheets?_where=(totalReadings,gt,0)~and(id,ne,${datasheetId})~and(instrumentId,eq,${datasheetDetails?.instrumentId})~and(DUCID,eq,${datasheetDetails?.DUCID})~and((ranges,like,${datasheetDetails?.ranges.replaceAll(
          "#",
          "_"
        )})~and(lc,like,${datasheetDetails?.lc.replaceAll("#", "_")}))`,
      ]);
    if (datasheetDetails?.ranges && datasheetDetails?.lc)
      queries = queries.concat([
        `datasheets?_where=(totalReadings,gt,0)~and(id,ne,${datasheetId})~and(instrumentId,eq,${datasheetDetails?.instrumentId
        })~and((ranges,like,${datasheetDetails?.ranges.replaceAll(
          "#",
          "_"
        )})~and(lc,like,${datasheetDetails?.lc.replaceAll("#", "_")}))`,
      ]);
    if (datasheetDetails?.model && !datasheetDetails?.model?.includes("-","NA"))
      queries = queries.concat([
        `datasheets?_where=(totalReadings,gt,0)~and(id,ne,${datasheetId})~and(instrumentId,eq,${datasheetDetails?.instrumentId})~and(model,eq,${datasheetDetails?.model})`,
      ]);
    if (datasheetDetails?.serialNo && !datasheetDetails?.serialNo?.includes("-","NA"))
      queries = queries.concat([
        `datasheets?_where=(totalReadings,gt,0)~and(id,ne,${datasheetId})~and(instrumentId,eq,${datasheetDetails?.instrumentId})~and(serialNumber,eq,${datasheetDetails?.serialNo})`,
      ]);

    // Fetch datasheets
    let res = [];
    for (let i = 0; i < queries.length; i++) {
      res = await axiosWithToken.get(BASE_URL + queries[i]);
      if (res.data.length > 0) break;
    }

    // Set job numbers to state
    let jobDetails = res?.data?.map(item => ({
      id: item.id,
      jobNumber: item.JobNumber
    })) || [];
    return jobDetails;
  } catch (error) {
    console.error('Error fetching job numbers:', error);
    return []; // Return empty array in case of error
  }
};


// TODO: simplify this function
export async function fetchOtherStaticReadingRows(
  datasheetId,
  datasheetStaticReadings,
  datasheetDetails,
  dispatch
) {
  // 1. prepare query for fetching datasheet
  let queries = [];
  if (datasheetDetails?.DUCID)
    queries = queries.concat([
      `datasheets?_where=(totalReadings,gt,0)~and(id,ne,${datasheetId})~and(instrumentId,eq,${datasheetDetails?.instrumentId})~and(DUCID,eq,${datasheetDetails?.DUCID})`,
    ]);
  if (datasheetDetails?.ranges && datasheetDetails?.lc)
    queries = queries.concat([
      `datasheets?_where=(totalReadings,gt,0)~and(id,ne,${datasheetId})~and(instrumentId,eq,${datasheetDetails?.instrumentId
      })~and((ranges,like,${datasheetDetails?.ranges.replaceAll(
        "#",
        "_"
      )})~and(lc,like,${datasheetDetails?.lc.replaceAll("#", "_")}))`,
    ]);
  if (datasheetDetails?.model)
    queries = queries.concat([
      `datasheets?_where=(totalReadings,gt,0)~and(id,ne,${datasheetId})~and(instrumentId,eq,${datasheetDetails?.instrumentId})~and(model,ne,${datasheetDetails?.model})`,
    ]);
  if (datasheetDetails?.serialNo)
    queries = queries.concat([
      `datasheets?_where=(totalReadings,gt,0)~and(id,ne,${datasheetId})~and(instrumentId,eq,${datasheetDetails?.instrumentId})~and(serialNumber,ne,${datasheetDetails?.serialNo})`,
    ]);

  // 2. fetch datasheet having >1 readings
  let res = [];
  for (let i = 0; i < queries.length; i++) {
    res = await axiosWithToken.get(BASE_URL + queries[i]);
    if (res.data.length > 0) break;
  }

  // 3. pick random datasheetId from the fetched datasheets
  let datasheetIds = res.data?.map((row) => row.id);
  let _datasheetId = 0;
  if (datasheetIds?.length > 0) {
    _datasheetId =
      datasheetIds[Math.floor(Math.random() * datasheetIds.length)];

    // 4. clear previous readings
    let readingIds = datasheetStaticReadings
      .filter((e) => e.datasheetId == datasheetId)
      .map((row) => row.id);
    if (readingIds.length > 0)
      await clearPreviousReadings("datasheetStaticReadings", "datasheetId", datasheetId);

    // 5. fetch readings for the selected datasheet
    fetchStaticReadingRows(_datasheetId, dispatch, true, datasheetId, datasheetDetails?.deletedTableIds);
  }
}

export async function fetchafterAutoloadJobNumber(
  datasheetId,
  datasheetStaticReadings,
  datasheetDetails,
  dispatch,
  selectedDatasheetID,
  selectedMasterORNot
) {

  // 1. selected jobnumber datasheetId
  let datasheetIds = selectedDatasheetID;
  if (datasheetIds) {
    // 2. clear previous readings
    let readingIds = datasheetStaticReadings
      .filter((e) => e.datasheetId == datasheetId)
      .map((row) => row.id);
    if (readingIds.length > 0)
      await clearPreviousReadings("datasheetStaticReadings", "datasheetId", datasheetId);

    // 3. fetch readings for the selected datasheet
    fetchStaticReadingRows(datasheetIds, dispatch, true, datasheetId, datasheetDetails?.deletedTableIds, selectedMasterORNot);
  }
}

function fetchStandardRanges(ids = [], dispatch) {
  const convertStandardData = {
    ds_disciplineKey: "dsKey",
    pl_id: "id",
    pl_stId: "stId",
    pl_standardName: "title",
    pl_type: "type",
    pl_masterleastcount: "masterleastcount",
    pr_id: "rangeId",
    pr_rangeName: "range",
    pr_accuracy: "accuracy",
    pr_gravity: "lc",
    pr_uniformity: "uniformity",
    pr_stability: "stability",
    pr_mode: "mode",
    pr_parameter: "parameter",
    pr_etParameter: "etParameter",
    pr_paratype: "paratype",
    pr_reactionTime: "reactionTime",
    pr_cmv: "cmv",
    pr_mpe: "mpe",
    pr_drift: "drift",
    pr_materialDensity: "materialDensity",
    pr_deviation: "deviation"
  };

  axiosWithToken
    .get(
      BASE_URL +
      `xjoin?_join=pl.standards,_j,pr.standardRanges,_j,ds.discipline&_on1=(pl.id,eq,pr.standardId)&_on2=(pl.disciplineId,eq,ds.id)&_fields=pl.standardName,pr.rangeName,pl.id,pr.id,pl.stId,pr.rangeName,pr.accuracy,pr.gravity,pr.uniformity,pr.mode,pr.parameter,pr.etParameter,pr.paratype,pr.stability,pr.reactionTime,pr.cmv,pr.drift,pr.mpe,pr.materialDensity,pr.deviation,pl.type,pl.masterleastcount,pl.make,pl.traceability,pl.calDate,pl.validUpto,ds.disciplineKey&_where=(pl.status,eq,1)~and(pl.id,in,${ids[0]})`
      // 1. fetch standard masters
    )
    .then((res) => {
      let allStandardRangesData = [];
      res.data.forEach((d) => {
        allStandardRangesData.push({});
        Object.entries(d).forEach(
          (e) =>
          (allStandardRangesData[allStandardRangesData.length - 1][
            convertStandardData[e[0]] || e[0]
          ] = e[1])
        );
      });
      let standardRangesData = allStandardRangesData.filter(
        (st) => st.type == 1
      );
      dispatch({
        field: "standardRanges",
        value: standardRangesData,
      });

      let supportiveStandardMasterArray = allStandardRangesData.filter(
        (st) => st.type == 2
      );
      dispatch({
        field: "supportiveStandardMasterArray",
        value: supportiveStandardMasterArray,
      });
    })
    .catch((err) => {
      console.error("standards data fetching error: ", err);
    });
}

export function fetchUnits(disciplineId, dispatch) {
  return axiosWithToken
    .get(
      BASE_URL +
      "unit?_where=(status,eq,1)" +
      (disciplineId ? `~and(disciplineId,eq,${disciplineId})` : "")
    )
    .then((res) => {
      let unitSymbols = res.data.reduce((prev, current) => {
        return [...prev, current.symbol];
      }, []);
      unitSymbols = [...new Set(unitSymbols)];

      dispatch({
        field: "units",
        value: res.data,
      });

      dispatch({
        field: "unitSymbols",
        value: unitSymbols,
      });
    })
    .catch((err) => {
      console.error("unit fetching error: ", err);
      return err;
    });
}

export function fetchCmcReadings(instrumentId, dispatch) {
  return axiosWithToken
    .get(BASE_URL + `cmc?_where=(instrumentId,eq,${instrumentId})`)
    .then((res) => {
      dispatch({
        field: "cmcDetails",
        value: {
          'cmcReadings': res.data
        }
      });
    })
    .catch((err) => {
      console.error("Something Went Wrong while fetching standardRanges!");
      return err;
    });
}

// instrumentDetails section
// TODO: combine below 2 functions into 1 react hook
const filterAllowedMasters = async (mst, referenceData) => {
  let userType = Number(localStorage.getItem("type"));
  const user = [localStorage.getItem("id"), localStorage.getItem("userName")];

  if (userType !== 2) {
    return mst;
  }
  if (!mst) return mst;
  mst = (mst || "").split(",").map((e) => e.split(":")[0]);
  if (mst === "") return mst;
  let res = null;
  let SQLQuery = {
    query: `SELECT * FROM standardInOut WHERE status = 1 AND userId LIKE '%${user[0]}%' AND (returnDate IS NULL OR returnDate > current_date)`,
  };

  res = await axiosWithToken.post(BASE_URL + "dynamic", SQLQuery);
  let standardInOutData = res.data
    .filter((d) => d.purpose.toLowerCase() != "self-calibration")
    .map((e) => e.standardId)
    .join(",")
    .split(",");

  let filterMST = mst?.filter((c) => standardInOutData?.includes(c));

  if (res.data.length > 0 && filterMST.length > 0) {
    mst = filterMST;
    // return mst?.join(",")
  }

  // let finalStandardIDS=referenceData["srfInstruments"]?.location == "Onsite" ? "" : mst?.join(",")

  //   return finalStandardIDS

  return mst?.join(",");
};

export function fetchInstrument(instrumentId, dispatch, referenceData) {
  let url = BASE_URL;
  let sqlQuery = {
    query: `SELECT instruments.*, remarks.value as instrumentRemark FROM instruments LEFT JOIN remarks ON remarks.id = instruments.remarkId WHERE instruments.id = ${instrumentId}`,
  };
  return axiosWithToken
    .post(url + `dynamic`, sqlQuery)
    .then(async (res) => {
      if (res.data.length > 0) {
        dispatch({
          field: "instrumentDetails",
          value: res.data[0],
        });

        // extract standard ids
        let rawStandardIds = await filterAllowedMasters(
          res.data[0]?.standardMasterIds,
          referenceData
        );

        let standardIds = rawStandardIds
          .split(",")
          .map((value) => value.split(":")[0]);

        let rawSupportiveInstrumentMasterData =
          res.data[0]?.supportiveInstrumentMasterData || "";
        let supportiveInstrumentIds = rawSupportiveInstrumentMasterData
          .split(",")
          .map((value) => value.split(":")[0]);

        if (standardIds.length > 0 || supportiveInstrumentIds.length > 0)
          fetchStandardRanges(
            [standardIds.join(","), supportiveInstrumentIds.join(",")],
            dispatch
          );
      }
      return res;
    })
    .catch((err) => {
      console.error("instrumentDetails data fetching error: ", err);
      return err;
    });
}

// TODO: combine this function with fetchStandard
export async function fetchAllowedStandards(
  standardMasterIds,
  dispatch,
  referenceData
) {
  let combineArray = await filterAllowedMasters(
    standardMasterIds,
    referenceData
  );

  return axiosWithToken
    .get(BASE_URL + `standards?_where=(id,in,${combineArray.toString()})`)
    .then((res) => {
      dispatch({
        field: "standardMasterArray",
        value: [...res?.data],
      });

      return res;
    });
}

export function getReferenceData(datasheetId, dispatch) {
  let tables = ["srfInstruments"];
  let newReferenceData = {};
  axiosWithToken.get(`${BASE_URL}${tables[0]}/${datasheetId}`).then((res) => {
    newReferenceData[`${tables[0]}`] = res.data[0];
    dispatch({
      field: "referenceData",
      value: {
        ...newReferenceData,
      },
    });
  });
}

export const fetchDisciplineDetails = (id, dispatch) => {
  axiosWithToken
    .get(
      BASE_URL +
      `xjoin?_join=ds.datasheets,_j,ins.instruments,_j,disc.discipline&_on1=(ds.instrumentId,eq,ins.id)&_on2=(ins.disciplineId,eq,disc.id)&_fields=disc.documentNumber,disc.issueNo,disc.issueDate,disc.amendmentNo,disc.amendmentDate&_where=(disc.id,eq,${id})`
    )
    .then((res) => {
      dispatch({
        field: "disciplineDetails",
        value: {
          documentNumber: res.data[0]?.disc_documentNumber || "",
          issueNo: res.data[0]?.disc_issueNo || "",
          issueDate: res.data[0]?.disc_issueDate || "",
          amendmentNo: res.data[0]?.disc_amendmentNo || "",
          amendmentDate: res.data[0]?.disc_amendmentDate || "",
        },
      });
    });
};

export function fetchDatasheetReadingsAdditionalInfo(datasheetId, dispatch) {
  let fetchQuery = {
    query: `select * FROM datasheetReadingsAdditionalInfo WHERE datasheetReadingId=${datasheetId}`,
  };
  return axiosWithToken
    .post(BASE_URL + "dynamic", fetchQuery)
    .then((res) => {
      // Function to filter keys with non-null values
      function filterKeys(obj) {
        const filteredObj = {};
        Object.keys(obj).forEach((key) => {
          if (
            obj[key] !== null &&
            key !== "id" &&
            key !== "status" &&
            key !== "lastModified"
          ) {
            filteredObj[key] = obj[key];
          }
        });
        return filteredObj;
      }

      // Map and filter the res.data
      const filteredDataArray = res.data.map((obj) => filterKeys(obj));

      dispatch({
        field: "DatasheetReadingsAdditionalInfo",
        value: filteredDataArray,
      });
    })
    .catch((err) => {
      console.error("unit fetching error: ", err);
      return err;
    });
}


export function updateCertificateNumberDatsheet(
  certificateNumber,
  format,
  currentDate
) {
  // Function to extract date format from the given format string
  const extractDateFormat = (format) => {
    const dateRegex = /[YMD]{2,4}[\/\-]?[YMD]{0,4}[\/\-]?[YMD]{0,4}/;
    const match = format.match(dateRegex);
    return match ? match[0] : "DDMMYYYY";
  };

  // Function to format the date according to the provided format
  const formatDate = (date, format) => {
    const yy = String(date.getFullYear()).slice(-2);
    const yyyy = String(date.getFullYear());
    const mm = String(date.getMonth() + 1).padStart(2, "0");
    const dd = String(date.getDate()).padStart(2, "0");

    return format
      .replace("YYYY", yyyy)
      .replace("YY", yy)
      .replace("MM", mm)
      .replace("DD", dd);
  };
  // Extract date format from the certificateDateFormate
  const dateFormat = extractDateFormat(format);
  const oldDateRegex = new RegExp(
    dateFormat.replace(/[YMD]{2,4}/g, "\\d{2,4}").replace(/[\/\-]/g, "\\$&")
  );
  const oldDateMatch = certificateNumber.match(oldDateRegex);

  if (!oldDateMatch) {
    return certificateNumber;
  }

  const oldDate = oldDateMatch[0];
  const formattedDate = formatDate(currentDate, dateFormat);

  // Replace the old date with the new formatted date
  const newCertificateNumber = certificateNumber.replace(
    oldDate,
    formattedDate
  );

  return newCertificateNumber;
}

export async function getRemarksData(dispatch) {
  let paylod = {
    query: `SELECT label, value, id  FROM remarks`,
  };
  axiosWithToken
    .post(BASE_URL + `dynamic`, paylod)
    .then((res) => {
      dispatch({
        field: "Remarks",
        value: res?.data,
      });
    })
    .catch((err) => {
      console.error("remark data fetching error: ", err);
    });
}